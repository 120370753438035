
  <template>
  <div class="position-data">
    <div class="currency-btn">
      <el-radio-group v-model="radio">
        <el-radio-button label="BTC"></el-radio-button>
        <el-radio-button label="ETH"></el-radio-button>
      </el-radio-group>
    </div>
    <b-card no-body>
      <b-card-header>
        <b-card-title
          ><div class="header-title">
            <a
              href="https://lib.rta-finance.com/#/article?id=635"
              target="_blank"
              >{{ radio }}历史交割价格</a
            >

            <span>（{{ radio }} Historical Delivery Price）</span>
          </div>
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <div class="container-body">
          <Chart
            ref="chart"
            class="chart"
            :option="ivSlewOption"
            :loading="loading"
          />
        </div>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <b-card-title
          ><div class="header-title">
            <a
              href="https://lib.rta-finance.com/#/article?id=636"
              target="_blank"
            >
              {{ radio }}最大痛点</a
            >

            <span>（{{ radio }} Max Pain Price）</span>
          </div>
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <div class="container-body" style="margin-top: 8px">
          <div class="max">
            <span>Next Expiration Date: {{ this.maxDate }}</span
            ><span class="max-ma">Max Pain Price: ${{ this.maxP }} </span>
          </div>
          <Chart
            ref="chart1"
            class="chart"
            :option="threeIvSlewOption"
            :loading="loading1"
          />
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import * as myToolFn from "@/utils/echartsFullScreen";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
} from "bootstrap-vue";
import {
  fromatterPieOptions,
  number2String,
  graphic,
  graphicBig,
  SPLIT_LINE,
  NOT_DATA_OPTION,
} from "../../../utils/chart";
import Chart from "../../charts-and-maps/charts/chart.vue";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    Chart,
  },
  // props: {
  //   radio: { type: String },
  //   name: { type: String },
  //   activeNameTab: {
  //     type: String,
  //     required: true,
  //   },
  // }, //接收
  porps: ["option", "height"], //接收
  data() {
    return {
      radio: "BTC",
      name: "a",
      activeNameTab: "a",
      ivSlewOption: {},
      threeIvSlewOption: {},
      dateData: [],
      painPointData: [],
      priceData: [],

      maxDateData: [],
      maxpainPointData: [],
      maxDate: "",
      maxP: "",
      loading: true,
      loading1: true,
    };
  },
  created() {},
  computed: {},
  mounted() {
    // console.log(myToolFn, "mytoolfn");
  },
  watch: {
    activeNameTab(newValue) {
      this.getListDataPageAction(this.name, newValue, this.radio);
    },
    radio: {
      handler(newValue) {
        this.getListDataPageAction(this.name, this.activeNameTab, newValue);
      },
      immediate: true,
    },
  },
  methods: {
    //判断一下只执行当前选中的标签页
    getListDataPageAction(name, activeNameTab, radio) {
      if (name === activeNameTab && !this.isRepetition) {
        this.isRepetition = true;
        this.getHistorical(radio);
        this.getMaxPainPrice(radio);
      }
    },
    //获取历史交割价格数据
    getHistorical() {
      const params = {
        currency: this.radio,
      };
      this.axios
        .get("https://trade.rta-finance.com/trade/deribitother/delivery/", {
          params,
        })
        .then((res) => {
          if (res.data.code == "0") {
            this.isRepetition = false;
            let tempData = res.data.data;
            //console.log("BTC历史交割价格", tempData);
            this.dateData = tempData.map((v) => v.date);
            this.painPointData = tempData.map((v) => v.pain_point);
            this.priceData = tempData.map((v) => v.price);

            this.findIvSkew();
          } else {
            this.priceArr = [];
            this.ivArr = [];
            this.daysArr = [];
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.loading = false; // 确保在数据加载完成后隐藏加载动画
        });
    },
    findIvSkew() {
      const xData = this.dateData;
      const ayData = this.painPointData;
      const byData = this.priceData;
      this.ivSlewOption = {
        backgroundColor: "#33353e",
        color: "rgba(0, 174, 147, 1)",
        tooltip: {
          trigger: "axis",
          borderWidth: "0", //边框宽度设置1
          borderColor: "none", //设置边框颜色
          formatter: (params) => {
            let aaa = this.formatNum(params[0].data);
            let bbb = this.formatNum(params[1].data);
            let strike = this.formatNum(params[0].name);
            return `
                <div>
                  <div style="border-bottom: 1px solid rgba(151, 151, 151, 0.2); padding-bottom: 8px; margin-bottom: 8px">
                    ${params[0].name}
                  </div>
                  <div><span style="color:#4AB5E8">${params[0].seriesName} : ${aaa}</span><br/>
                   <span style="color:#EDBD88">${params[1].seriesName} : ${bbb}</span><br/></div>
                  </div>
              `;
          },
          padding: 20,
          textStyle: {
            fontSize: 12,
            color: "rgba(209, 212, 220, 1)", //设置文字颜色
          },
          tooltip: {
            show: true,
          },
          extraCssText: "background:rgba(61, 63, 71, 1);",
        },
        toolbox: {
          top: 20,
          right: "2%",
          iconStyle: {
            borderColor: "#D1D4DC",
          },
          emphasis: {
            iconStyle: {
              borderColor: "#edbd88", // 鼠标悬停时的图标颜色
            },
          },
          feature: {
            saveAsImage: {
              title: "Download Chart",
              icon: "path://M746.666667 597.333333a149.333333 149.333333 0 0 0-79.36 23.04l-123.093334-123.093333 333.013334-332.8a21.333333 21.333333 0 0 0 0-30.293333 21.333333 21.333333 0 0 0-30.08 0L514.133333 467.2 181.12 134.186667a21.333333 21.333333 0 0 0-30.08 0 21.333333 21.333333 0 0 0 0 30.293333l333.013333 332.8-124.8 124.8A147.626667 147.626667 0 0 0 277.333333 597.333333a149.333333 149.333333 0 1 0 113.92 53.12l122.88-122.88 120.533334 120.533334A149.333333 149.333333 0 1 0 746.666667 597.333333zM277.333333 853.333333a106.666667 106.666667 0 1 1 106.666667-106.666666 106.666667 106.666667 0 0 1-106.666667 106.666666z m469.333334 0a106.666667 106.666667 0 1 1 106.666666-106.666666 106.666667 106.666667 0 0 1-106.666666 106.666666z",
            },
            restore: {
              show: true,
              title: "Restore",
            },
            myFull: myToolFn.myFull(() => this.$refs.chart.$el),
            myDownloadCSV: myToolFn.downloadCSV(() => [
              this.$refs.chart.option.xAxis,
              ...this.$refs.chart.option.series.map((v) => v.data),
            ]),
          },
        },
        grid: {
          left: "2%",
          right: "3%",
          bottom: "5%",
          top: "15%", //这个加不上，我截图上面没边距
          containLabel: true,
        },
        legend: {
          top: 20,
          top: 20,
          textStyle: {
            fontSize: 12,
            color: "#D1D4DC",
          },
          padding: [10, 32, 0, 0],
          itemHeight: 4,
          itemWidth: 12,
          itemGap: 40,
          data: [
            {
              name: "历史交割价格",
              icon: "rect",
            },
            {
              name: "历史痛点",
              icon: "rect",
            },
          ],
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xData,
          //data: ['2022-09-13', '2022-09-12', '2022-09-11', '2022-09-10', '2022-09-09', '2022-09-08', '2022-09-07'],
          offset: 12,
          axisLabel: {
            textStyle: {
              color: "rgba(209, 212, 220, 1)",
            },
          },
        },
        yAxis: [
          {
            type: "value",
            min: "dataMin",
            // name: "IV（%）",
            splitLine: {
              lineStyle: {
                type: "dashed",
                color: ["rgba(159, 159, 159, 0.1)"],
              },
            },
            axisLabel: {
              textStyle: {
                color: "rgba(209, 212, 220, 1)",
              },
              margin: 20,
            },
          },
        ],
        series: [
          {
            name: "历史交割价格",
            data: byData,
            type: "line",
            symbol: "circle",
            showSymbol: false,
            lineStyle: {
              color: "rgba(74, 181, 232, 1)",
              width: 1.5,
            },
            itemStyle: {
              normal: {
                color: "rgba(74, 181, 232, 1)",
              },
            },
          },
          {
            name: "历史痛点",
            data: ayData,
            type: "line",
            symbol: "circle",
            showSymbol: false,
            lineStyle: {
              normal: {
                color: "rgba(237, 189, 136, 1)",
                type: "dashed",
                width: 1.5,
              },
            },
            itemStyle: {
              normal: {
                color: "rgba(237, 189, 136, 1)",
              },
            },
          },
        ],
        graphic: graphicBig("echarts-bg_yYgTFo1.png"),
      };
    },
    //获取最大痛点数据
    getMaxPainPrice() {
      const params = {
        currency: this.radio,
      };
      this.axios
        .get("https://trade.rta-finance.com/trade/deribitother/painpoint/", {
          params,
        })
        .then((res) => {
          if (res.data.code == "0") {
            this.isRepetition = false;
            let tempData = res.data.data;
            let sortedTempData = tempData.slice().sort(function (a, b) {
              return new Date(a.date) - new Date(b.date);
            });
            //"最大痛点", tempData);
            this.maxDateData = sortedTempData.map((v) =>
              this.dateTypeFormat("YYYY-mm-dd HH:MM", new Date(v.date))
            );
            this.maxpainPointData = sortedTempData.map((v) => v.pain_point);
            let maxPainPointObject = sortedTempData[0]; // 初始化最大值对象为数组的第一个元素

            for (let i = 1; i < sortedTempData.length; i++) {
              if (
                sortedTempData[i].pain_point > maxPainPointObject.pain_point
              ) {
                maxPainPointObject = sortedTempData[i]; // 更新最大值对象
              }
            }
            let formattedDate = maxPainPointObject.date;
            this.maxP = maxPainPointObject.pain_point;
            let dateObj = new Date(formattedDate);

            // 获取日期、月份和年份的值
            let day = dateObj.getDate(); // 获取日期
            let month = dateObj.toLocaleString("en-US", { month: "short" }); // 明确指定英文地区设置
            let year = dateObj.getFullYear().toString().substr(-2); // 获取年份的后两位

            // 将它们组合成所需格式
            this.maxDate = `${day}-${month}-${year}`;

            this.findthreeIvSkew();
          } else {
            this.maxDateData = [];
            this.maxpainPointData = [];
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.loading1 = false; // 确保在数据加载完成后隐藏加载动画
        });
    },
    findthreeIvSkew() {
      const xData = this.maxDateData;
      const ayData = this.maxpainPointData;
      this.threeIvSlewOption = {
        backgroundColor: "#33353e",
        color: "rgba(0, 174, 147, 1)",
        tooltip: {
          trigger: "axis",
          borderWidth: "0", //边框宽度设置1
          borderColor: "none", //设置边框颜色
          formatter: (params) => {
            let aaa = this.kmb(params[0].data);
            return `
                <div>
                  <div style="border-bottom: 1px solid rgba(151, 151, 151, 0.2); padding-bottom: 8px; margin-bottom: 8px">
                    ${params[0].name}
                  </div>
                  <span >${params[0].seriesName} : ${aaa}</span><br/>
                   </div>
                  
              `;
          },
          padding: 20,
          textStyle: {
            fontSize: 12,
            color: "rgba(209, 212, 220, 1)", //设置文字颜色
          },
          tooltip: {
            show: true,
          },
          extraCssText: "background:rgba(61, 63, 71, 1);",
        },
        toolbox: {
          top: 20,
          right: "2%",
          iconStyle: {
            borderColor: "#D1D4DC",
          },
          emphasis: {
            iconStyle: {
              borderColor: "#edbd88", // 鼠标悬停时的图标颜色
            },
          },
          feature: {
            saveAsImage: {
              title: "Download Chart",
              icon: "path://M746.666667 597.333333a149.333333 149.333333 0 0 0-79.36 23.04l-123.093334-123.093333 333.013334-332.8a21.333333 21.333333 0 0 0 0-30.293333 21.333333 21.333333 0 0 0-30.08 0L514.133333 467.2 181.12 134.186667a21.333333 21.333333 0 0 0-30.08 0 21.333333 21.333333 0 0 0 0 30.293333l333.013333 332.8-124.8 124.8A147.626667 147.626667 0 0 0 277.333333 597.333333a149.333333 149.333333 0 1 0 113.92 53.12l122.88-122.88 120.533334 120.533334A149.333333 149.333333 0 1 0 746.666667 597.333333zM277.333333 853.333333a106.666667 106.666667 0 1 1 106.666667-106.666666 106.666667 106.666667 0 0 1-106.666667 106.666666z m469.333334 0a106.666667 106.666667 0 1 1 106.666666-106.666666 106.666667 106.666667 0 0 1-106.666666 106.666666z",
            },
            restore: {
              show: true,
              title: "Restore",
            },
            myFull: myToolFn.myFull(() => this.$refs.chart1.$el),
            myDownloadCSV: myToolFn.downloadCSV(() => [
              this.$refs.chart1.option.xAxis,
              ...this.$refs.chart1.option.series.map((v) => v.data),
            ]),
          },
        },
        grid: {
          left: "2%",
          right: "3%",
          bottom: "5%",
          top: "15%",
          containLabel: true,
        },

        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xData,
          //data: ['2022-09-13', '2022-09-12', '2022-09-11', '2022-09-10', '2022-09-09', '2022-09-08', '2022-09-07'],
          offset: 12,
          axisLabel: {
            textStyle: {
              color: "rgba(209, 212, 220, 1)",
            },
            formatter: function (value) {
              const date = new Date(value);
              const year = date.getFullYear();
              const month = date.getMonth() + 1;
              const day = date.getDate();
              return (
                year +
                "-" +
                (month < 10 ? "0" : "") +
                month +
                "-" +
                (day < 10 ? "0" : "") +
                day
              );
            },
          },
        },
        yAxis: {
          type: "value",
          name: "Strike Price",
          splitLine: {
            lineStyle: {
              color: ["rgba(159, 159, 159, 0.1)"],
            },
          },
          axisLabel: {
            // formatter: function (v) {
            //   // console.log(v);
            //   if (v > 1000 && v <= 1000000) {
            //     return v / 1000 + "K";
            //   } else if (v > 1000000 && v <= 1000000000) {
            //     return v / 1000000 + "M";
            //   } else if (v > 1000000000) {
            //     return v / 1000000000 + "B";
            //   } else {
            //     return v;
            //   }
            //   // return v + 'K';
            // },
            textStyle: {
              color: "rgba(209, 212, 220, 1)",
            },
            margin: 20,
          },
        },
        series: [
          {
            name: "最大痛点",
            data: ayData,
            type: "line",
            symbol: "circle",
            showSymbol: false,
            lineStyle: {
              color: "rgba(237, 189, 136, 1)",
            },
            itemStyle: {
              normal: {
                color: "rgba(237, 189, 136, 1)",
              },
            },
            areaStyle: {
              normal: {
                color: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0.1,
                      color: "rgba(237,189,136,0.2000)", // 0% 处的颜色
                    },
                    {
                      offset: 0.9,
                      color: "rgba(237,189,136,0)", // 100% 处的颜色
                    },
                  ],
                  globalCoord: false, // 缺省为 false
                },
              },
            },
          },
        ],
        graphic: graphicBig("echarts-bg_yYgTFo1.png"),
      };
    },
  },
};
</script>
<style lang="scss" >
@import "./scss/option.scss";
.currency-btn {
  margin-bottom: 2rem;
  width: 100%;
  border-bottom: 1px solid rgba(239, 195, 148, 0.3);
  .el-radio-group {
    .el-radio-button {
      margin: 0 32px 0 0;
      .el-radio-button__inner {
        background: transparent !important;
        border: none;
        height: 44px;
        line-height: 44px;
        padding: 0 !important;
        font-size: 20px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #d1d4dc;
        border-radius: 0 !important;
      }
      .el-radio-button__inner:focus {
        border-bottom: 2px solid #edbd88;
        color: #edbd88;
      }
      .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        border-bottom: 2px solid #edbd88;
        color: #edbd88;
      }
    }
  }
}
.container-body {
  .max {
    span {
      font-size: 14px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #d1d4dc;
      line-height: 18px;
    }
    .max-ma {
      margin-left: 40px;
    }
  }
}
</style>