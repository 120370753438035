<template>
  <div class="position-data">
    <div class="currency-btn">
      <el-radio-group v-model="radio">
        <el-radio-button label="BTC"></el-radio-button>
        <el-radio-button label="ETH"></el-radio-button>
      </el-radio-group>
    </div>
    <b-card no-body>
      <b-card-header>
        <b-card-title
          ><div class="header-title">
            <a
              href="https://lib.rta-finance.com/#/article?id=630"
              target="_blank"
              >{{ radio }}平值期权隐含波动率</a
            >
            <span>（{{ radio }} ATM Implied Volatility）</span>
          </div>
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <div class="select-container">
          <div class="chart-option filter" style="margin-top: 16px">
            <el-select
              v-model="PainPoints.day"
              placeholder=""
              @change="timeChange($event)"
            >
              <el-option
                v-for="item in dataOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="container-body">
          <Chart ref="chart" class="chart" :option="assetsOption" />
        </div>
      </b-card-body>
    </b-card>
    <b-row class="">
      <b-col lg="6">
        <b-card no-body>
          <b-card-header>
            <b-card-title class=""
              ><div class="header-title">
                <a
                  href="https://lib.rta-finance.com/#/article?id=631"
                  target="_blank"
                >
                  {{ radio }}历史波动率</a
                >
                <span>（{{ radio }} Historical Volatility）</span>
              </div>
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <div class="container-body">
              <Chart ref="chart1" class="chart" :option="historicalOption" />
            </div>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col lg="6">
        <b-card no-body style="height: 485px">
          <b-card-header>
            <b-card-title class=""
              ><div class="header-title">
                <a
                  href="https://lib.rta-finance.com/#/article?id=632"
                  target="_blank"
                  >比特币平值期权期限结构曲线</a
                >

                <span>（{{ radio }} ATM Volatility Term Structure）</span>
              </div>
            </b-card-title>
          </b-card-header>
          <b-card-body>
            <!-- <div class="select-container">
              <el-date-picker v-model="value1" type="date" placeholder="">
              </el-date-picker>
            </div> -->
            <div class="container-body" style="margin-top: 12px">
              <Chart ref="chart2" class="chart" :option="volatilityOption" />
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-card no-body>
      <b-card-header>
        <b-card-title class=""
          ><div class="header-title">
            <a
              href="https://lib.rta-finance.com/#/article?id=633"
              target="_blank"
              >比特币隐含波动率曲面</a
            >

            <span>（{{ radio }} Implied Volatility Surface）</span>
          </div>
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <b-row class="">
          <b-col lg="6">
            <div class="container-body-distribution">
              <div class="container-body-title">隐含波动率曲面</div>
              <div class="charts">
                <Chart ref="chart3" :height="aa" :option="ivOption" />
              </div>
            </div>
          </b-col>

          <b-col lg="6">
            <div class="container-body-distribution">
              <div class="container-body-title">波动率偏差</div>
              <div class="charts">
                <Chart
                  ref="chart4"
                  :height="aa"
                  class="chart"
                  :option="ivDiffOption"
                />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <b-card-title class=""
          ><div class="header-title">
            <a
              href="https://lib.rta-finance.com/#/article?id=634"
              target="_blank"
              >比特币Skew结构曲线</a
            >

            <span>（{{ radio }} XXX IV Skew）</span>
          </div>
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <b-row class="">
          <b-col lg="6">
            <div class="container-body-distribution">
              <div class="container-body-title">
                {{ radio }} {{ oneUnderlyingIndex }} IV Skew<span
                  >——（近期（1 month））</span
                >
              </div>
              <div class="charts">
                <Chart ref="chart5" :option="ivSlewOption" />
              </div>
            </div>
          </b-col>

          <b-col lg="6">
            <div class="container-body-distribution">
              <div class="container-body-title">
                {{ radio }} {{ threeUnderlyingIndex }} IV Skew<span
                  >——（中期（3 month））</span
                >
              </div>
              <div class="charts">
                <Chart class="chart" ref="chart6" :option="threeIvSlewOption" />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
} from "bootstrap-vue";
import {
  fromatterPieOptions,
  number2String,
  graphic,
  graphicBig,
  SPLIT_LINE,
  NOT_DATA_OPTION,
} from "../../../utils/chart";
import * as myToolFn from "@/utils/echartsFullScreen";
import Chart from "../../charts-and-maps/charts/chart.vue";
import BarCharts from "../../charts-and-maps/charts/barCharts.vue";
import CustomizedPie from "@/views/charts-and-maps/charts/echart/option-echart/CustomizedPie.vue";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    Chart,
    BarCharts,
    CustomizedPie,
  },
  props: {
    // radio: { type: String },
    // name: { type: String },
    // activeNameTab: {
    //   type: String,
    //   required: true,
    // },
  }, //接收
  porps: ["option", "height"], //接收
  data() {
    return {
      radio: "BTC",
      name: "a",
      activeNameTab: "a",
      PainPoints: {
        min_price: "",
        max_price: "",
        day: "year",
      },
      dataOptions: [
        {
          value: "day",
          label: "天",
        },
        {
          value: "month",
          label: "月",
        },
        {
          value: "quarter",
          label: "季度",
        },
        {
          value: "halfYear",
          label: "半年",
        },
        {
          value: "year",
          label: "一年",
        },
        {
          value: "all",
          label: "全部",
        },
      ],
      height: "356px",
      aa: "420px",
      assetsOption: {},
      historicalOption: {},
      volatilityOption: {},
      ivOption: {},
      ivDiffOption: {},
      atmIvOption: {},
      ivSlewOption: {},
      threeIvSlewOption: {},
      value1: "",
      oneMonthIv: [],
      threeMonthIv: [],
      sixMonthIv: [],
      ivTime: [],
      hv: [],
      hvX: [],
      fwdIv: [],
      structIv: [],
      painPoint: [],
      structDate: [],
      //隐含波动率
      priceArr: [],
      ivArr: [],
      daysArr: [],
      ivDiffArr: [],
      //skew近期一周
      oneStrike: [],
      oneIv: [],
      oneDelta: [],
      oneUnderlyingIndex: "",
      threeStrike: [],
      threeIv: [],
      threeDelta: [],
      threeUnderlyingIndex: "",
      tempDataa: [],
      processedArray: [],
      ivResult: [],
    };
  },
  created() {
    this.findVolatility(); //BTC平值期权隐含波动率展示
    this.findHistoricalVolatility(); //BTC历史波动率
    this.findVolatilityStructure(); //比特币平值期权期限结构曲线
    // this.findIvSurface(); //比特币隐含波动率曲面IV
    this.findAtmSurface(); //比特币隐含波动率曲面IV
    this.findIvSkew(); //比特币Skew结构曲线
    // this.findFwdIvSurface();
  },
  computed: {},
  mounted() {
    // this.findIvSurface();
    // this.$nextTick(() => {
    //   this.findIvSurface();
    // });
  },
  watch: {
    activeNameTab(newValue) {
      this.getListDataPageAction(this.name, newValue, this.radio);
    },
    radio: {
      handler(newValue) {
        this.getListDataPageAction(this.name, this.activeNameTab, newValue);
      },
      immediate: true,
    },
  },
  methods: {
    //判断一下只执行当前选中的标签页
    getListDataPageAction(name, activeNameTab, radio) {
      if (name === activeNameTab && !this.isRepetition) {
        this.isRepetition = true;
        this.getVolatilityData(radio);
        this.getivhistoryData(radio);
        this.getStructData(radio);
        this.getSurfaceData(radio);
        this.getOneSkew(radio);
        this.getThreeSkew(radio);
        // this.getBrockData(radio);
      }
    },
    timeChange(item, label) {
      //console.log("时间", item, label);
      this.PainPoints.day = item;
      this.oneMonthIv = [];
      this.threeMonthIv = [];
      this.sixMonthIv = [];
      this.ivTime = [];
      this.getVolatilityData();
    },
    //获取平值期权隐含波动率全部数据
    getVolatilityData() {
      const params = {
        currency: this.radio,
        timeType: this.PainPoints.day,
      };
      this.axios
        .get("https://trade.rta-finance.com/trade/deribitiv/ivinfo/", {
          params,
        })
        .then((res) => {
          if (res.data.code == "0") {
            this.isRepetition = false;
            let tempData = res.data.data;
            //console.log("波动率", tempData);
            this.oneMonthIv = tempData.map((v) =>
              parseFloat(v.one_month_iv).toFixed(2)
            );
            this.threeMonthIv = tempData.map((v) =>
              parseFloat(v.three_month_iv).toFixed(2)
            );
            this.sixMonthIv = tempData.map((v) =>
              parseFloat(v.six_month_iv).toFixed(2)
            );

            this.ivTime = tempData.map((v) =>
              this.dateTypeFormat(
                "YYYY-mm-dd HH:MM",
                new Date(v.day ? v.day : v.time)
              )
            );
            this.findVolatility();
            //console.log("时间", this.ivTime);
          } else {
            this.oneMonthIv = [];
            this.threeMonthIv = [];
            this.sixMonthIv = [];
            this.ivTime = [];
          }
        })
        .catch((err) => {});
    },

    findVolatility() {
      const xData = this.ivTime;
      const ayData = this.oneMonthIv;
      const byData = this.threeMonthIv;
      const cyData = this.sixMonthIv;
      const seriesData = [
        {
          name: "1 month",
          data: ayData,
          type: "line",
          symbol: "circle",
          showSymbol: false,
          lineStyle: {
            color: "rgba(234, 204, 174, 1)",
            width: 1.5,
          },
          itemStyle: {
            normal: {
              color: "rgba(234, 204, 174, 1)",
            },
          },
        },
        {
          name: "3 month",
          data: byData,
          type: "line",
          symbol: "circle",
          showSymbol: false,
          lineStyle: {
            color: "rgba(255, 82, 96, 1)",
            width: 1.5,
          },

          itemStyle: {
            normal: {
              color: "rgba(255, 82, 96, 1)",
            },
          },
        },
        {
          name: "6 month",
          data: cyData,
          type: "line",
          symbol: "circle",
          showSymbol: false,
          lineStyle: {
            color: "rgba(74, 181, 232, 1)",
            width: 1.5,
          },
          itemStyle: {
            normal: {
              color: "rgba(74, 181, 232, 1)",
            },
          },
        },
      ];

      this.assetsOption = {
        backgroundColor: "#33353e",
        color: "rgba(0, 174, 147, 1)",
        tooltip: {
          trigger: "axis",
          borderWidth: "0",
          borderColor: "none",
          formatter: (params) => {
            let result = `<div style="border-bottom: 1px solid rgba(151, 151, 151, 0.2); padding-bottom: 8px; margin-bottom: 8px">
                    ${params[0].name}
                  </div>`;
            params.forEach((item) => {
              var re = item.seriesName;
              var colors = "";
              switch (re) {
                case "1 month":
                  colors = "rgba(234, 204, 174, 1)";
                  break;
                case "3 month":
                  colors = "rgba(255, 82, 96, 1)";
                  break;
                case "6 month":
                  colors = "rgba(74, 181, 232, 1)";
                  break;
              }
              var aaa = this.formatNum(item.data);
              result += `<span style="color:${colors} ;">${item.seriesName} : ${aaa}</span><br/>  `;
            });
            return result;
            // let aaa = this.kmb(params[0].data);
            // let bbb = this.kmb(params[1].data);
            // let ccc = this.kmb(params[2].data);
            // return `
            //     <div>
            //       <div style="border-bottom: 1px solid rgba(151, 151, 151, 0.2); padding-bottom: 8px; margin-bottom: 8px">
            //         ${params[0].name}
            //       </div>
            //       <div><span style="color:#4AB5E8">${params[0].seriesName}:  ${aaa}</span><br/>
            //        <span style="color:#EACCAE">${params[1].seriesName}: ${bbb}</span><br/>
            //       <span style="color:#FF5260">${params[2].seriesName}: ${ccc}</span><br/></div>

            //     </div>
            //   `;
          },
          padding: 20,
          textStyle: {
            fontSize: 12,
            color: "rgba(209, 212, 220, 1)", //设置文字颜色
          },
          tooltip: {
            show: true,
          },
          extraCssText: "background:rgba(61, 63, 71, 1);",
        },
        toolbox: {
          top: 20,
          right: "2%",
          iconStyle: {
            borderColor: "#D1D4DC",
          },
          emphasis: {
            iconStyle: {
              borderColor: "#edbd88", // 鼠标悬停时的图标颜色
            },
          },
          feature: {
            saveAsImage: {
              title: "Download Chart",
              icon: "path://M746.666667 597.333333a149.333333 149.333333 0 0 0-79.36 23.04l-123.093334-123.093333 333.013334-332.8a21.333333 21.333333 0 0 0 0-30.293333 21.333333 21.333333 0 0 0-30.08 0L514.133333 467.2 181.12 134.186667a21.333333 21.333333 0 0 0-30.08 0 21.333333 21.333333 0 0 0 0 30.293333l333.013333 332.8-124.8 124.8A147.626667 147.626667 0 0 0 277.333333 597.333333a149.333333 149.333333 0 1 0 113.92 53.12l122.88-122.88 120.533334 120.533334A149.333333 149.333333 0 1 0 746.666667 597.333333zM277.333333 853.333333a106.666667 106.666667 0 1 1 106.666667-106.666666 106.666667 106.666667 0 0 1-106.666667 106.666666z m469.333334 0a106.666667 106.666667 0 1 1 106.666666-106.666666 106.666667 106.666667 0 0 1-106.666666 106.666666z",
            },
            restore: {
              show: true,
              title: "Restore",
            },
            myFull: myToolFn.myFull(() => this.$refs.chart.$el),
            myDownloadCSV: myToolFn.downloadCSV(() => [
              this.$refs.chart.option.xAxis,
              ...this.$refs.chart.option.series.map((v) => v.data),
            ]),
          },
        },
        grid: {
          left: "2%",
          right: "2%",
          bottom: "20%",
          top: 80,
          containLabel: true,
        },
        legend: {
          top: 20,
          textStyle: {
            fontSize: 12,
            color: "#D1D4DC",
          },
          padding: [0, 32, 0, 0],
          itemHeight: 4,
          itemWidth: 12,
          itemGap: 40,
          data: [
            {
              name: "1 month",
              icon: "rect",
            },
            {
              name: "3 month",
              icon: "rect",
            },
            {
              name: "6 month",
              icon: "rect",
            },
          ],
          formatter: function (name) {
            // 在这里添加括号和对应的 value 值加 '%'
            var value = ""; // 这里根据 name 获取相应的 value 值
            for (var i = 0; i < seriesData.length; i++) {
              if (seriesData[i].name === name) {
                var data = seriesData[i].data;
                value = parseFloat(data[data.length - 1]).toFixed(2);
                break;
              }
            }
            return name + " (" + value + "%)";
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xData,
          //data: ['2022-09-13', '2022-09-12', '2022-09-11', '2022-09-10', '2022-09-09', '2022-09-08', '2022-09-07'],
          offset: 12,
          axisLabel: {
            textStyle: {
              color: "rgba(209, 212, 220, 1)",
            },
            formatter: function (value) {
              const date = new Date(value);
              const year = date.getFullYear();
              const month = date.getMonth() + 1;
              const day = date.getDate();
              return (
                year +
                "-" +
                (month < 10 ? "0" : "") +
                month +
                "-" +
                (day < 10 ? "0" : "") +
                day
              );
            },
          },
        },
        yAxis: {
          type: "value",
          min: "dataMin",
          name: "IV（%）",
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: ["rgba(159, 159, 159, 0.1)"],
            },
          },
          axisLabel: {
            textStyle: {
              color: "rgba(209, 212, 220, 1)",
            },
            margin: 20,
          },
        },
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 100,
          },
          {
            start: 0,
            end: 100,
          },
        ],
        series: seriesData,
        graphic: graphicBig("echarts-bg_yYgTFo1.png"),
      };
    },
    //获取历史波动率全部数据
    getivhistoryData() {
      const params = {
        currency: this.radio,
      };
      this.axios
        .get("https://trade.rta-finance.com/trade/deribitiv/ivhistory/", {
          params,
        })
        .then((res) => {
          if (res.data.code == "0") {
            this.isRepetition = false;
            let tempData = res.data.data;

            // console.log("历史波动率", tempData);
            this.hv = tempData.map((v) => v.iv);
            this.hvX = tempData.map((v) =>
              this.dateTypeFormat("YYYY-mm-dd HH:MM", new Date(v.time))
            );
            this.findHistoricalVolatility();
          } else {
            this.hv = [];
            this.hvX = [];
          }
        })
        .catch((err) => {});
    },
    findHistoricalVolatility() {
      const xData = this.hvX;
      const ayData = this.hv;
      const seriesData = [
        {
          name: "HV",
          data: ayData,
          type: "line",
          symbol: "circle",
          showSymbol: false,
          lineStyle: {
            color: "rgba(237, 189, 136, 1)",
            width: 1.5,
          },
          itemStyle: {
            normal: {
              color: "rgba(237, 189, 136, 1)",
            },
          },
        },
      ];
      this.historicalOption = {
        backgroundColor: "#33353e",
        color: "rgba(0, 174, 147, 1)",
        tooltip: {
          trigger: "axis",
          borderWidth: "0", //边框宽度设置1
          borderColor: "none", //设置边框颜色
          formatter: (params) => {
            let result = `<div style="border-bottom: 1px solid rgba(151, 151, 151, 0.2); padding-bottom: 8px; margin-bottom: 8px">
                    ${params[0].name}
                  </div>`;

            params.forEach((item) => {
              var aaa = this.kmb(item.value);
              result += `<span style="color:#EACCAE">${item.seriesName} :${aaa}%</span></br>`;
            });
            return result;
          },
          padding: 20,
          textStyle: {
            fontSize: 12,
            color: "rgba(209, 212, 220, 1)", //设置文字颜色
          },
          tooltip: {
            show: true,
          },
          extraCssText: "background:rgba(61, 63, 71, 1);",
        },
        toolbox: {
          top: 20,
          right: "2%",
          iconStyle: {
            borderColor: "#D1D4DC",
          },
          emphasis: {
            iconStyle: {
              borderColor: "#edbd88", // 鼠标悬停时的图标颜色
            },
          },
          feature: {
            saveAsImage: {
              title: "Download Chart",
              icon: "path://M746.666667 597.333333a149.333333 149.333333 0 0 0-79.36 23.04l-123.093334-123.093333 333.013334-332.8a21.333333 21.333333 0 0 0 0-30.293333 21.333333 21.333333 0 0 0-30.08 0L514.133333 467.2 181.12 134.186667a21.333333 21.333333 0 0 0-30.08 0 21.333333 21.333333 0 0 0 0 30.293333l333.013333 332.8-124.8 124.8A147.626667 147.626667 0 0 0 277.333333 597.333333a149.333333 149.333333 0 1 0 113.92 53.12l122.88-122.88 120.533334 120.533334A149.333333 149.333333 0 1 0 746.666667 597.333333zM277.333333 853.333333a106.666667 106.666667 0 1 1 106.666667-106.666666 106.666667 106.666667 0 0 1-106.666667 106.666666z m469.333334 0a106.666667 106.666667 0 1 1 106.666666-106.666666 106.666667 106.666667 0 0 1-106.666666 106.666666z",
            },
            restore: {
              show: true,
              title: "Restore",
            },
            myFull: myToolFn.myFull(() => this.$refs.chart1.$el),
            myDownloadCSV: myToolFn.downloadCSV(() => [
              this.$refs.chart1.option.xAxis,
              ...this.$refs.chart1.option.series.map((v) => v.data),
            ]),
          },
        },
        grid: {
          left: "2%",
          right: "3%",
          bottom: "20%",
          containLabel: true,
        },
        legend: {
          top: 20,
          textStyle: {
            fontSize: 12,
            color: "#D1D4DC",
          },
          padding: [0, 32, 0, 0],
          itemHeight: 4,
          itemWidth: 12,
          itemGap: 40,
          data: [
            {
              name: "HV",
              icon: "rect",
            },
          ],
          formatter: function (name) {
            // 在这里添加括号和对应的 value 值加 '%'
            var value = ""; // 这里根据 name 获取相应的 value 值
            for (var i = 0; i < seriesData.length; i++) {
              if (seriesData[i].name === name) {
                var data = seriesData[i].data;
                value = parseFloat(data[data.length - 1]).toFixed(2);
                break;
              }
            }
            return name + " (" + value + "%)";
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xData,
          //data: ['2022-09-13', '2022-09-12', '2022-09-11', '2022-09-10', '2022-09-09', '2022-09-08', '2022-09-07'],
          offset: 12,
          axisLabel: {
            textStyle: {
              color: "rgba(209, 212, 220, 1)",
            },
            formatter: function (value) {
              const date = new Date(value);
              const year = date.getFullYear();
              const month = date.getMonth() + 1;
              const day = date.getDate();
              return (
                year +
                "-" +
                (month < 10 ? "0" : "") +
                month +
                "-" +
                (day < 10 ? "0" : "") +
                day
              );
            },
          },
        },
        yAxis: {
          type: "value",
          name: "HV（%）",
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: ["rgba(159, 159, 159, 0.1)"],
            },
          },
          axisLabel: {
            textStyle: {
              color: "rgba(209, 212, 220, 1)",
            },
            margin: 20,
          },
        },
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 100,
          },
          {
            start: 0,
            end: 100,
          },
        ],
        series: seriesData,
        graphic: graphicBig("echarts-bg_yYgTFo1.png"),
      };
    },
    //获取平值期权期全部数据
    getStructData() {
      const params = {
        currency: this.radio,
      };
      this.axios
        .get("https://trade.rta-finance.com/trade/deribitiv/struct/", {
          params,
        })
        .then((res) => {
          if (res.data.code == "0") {
            this.isRepetition = false;
            let tempData = res.data.data;
            if (tempData.length > 1) {
              tempData = tempData.slice(1);
            } else {
              tempData = [];
            }
            this.structIv = tempData.map((v) => v.iv.toFixed(2));
            this.painPoint = tempData.map((v) => v.painPoint);
            this.fwdIv = tempData.map((v) => v.fwdIv.toFixed(2));
            this.structDate = tempData.map((v) => v.expireDate);
            this.findVolatilityStructure();
          } else {
            this.oneMonthIv = [];
            this.threeMonthIv = [];
            this.sixMonthIv = [];
            this.ivTime = [];
            this.fwdIv = [];
          }
        })
        .catch((err) => {});
    },
    findVolatilityStructure() {
      const xData = this.structDate;
      const ayData = this.structIv;
      const byData = this.fwdIv;
      const cyData = this.painPoint;
      this.volatilityOption = {
        backgroundColor: "#33353e",
        color: "rgba(0, 174, 147, 1)",
        tooltip: {
          trigger: "axis",
          borderWidth: "0", //边框宽度设置1
          borderColor: "none", //设置边框颜色
          formatter: (params) => {
            let aaa = this.kmb(params[0].data);
            let bbb = this.kmb(params[1].data);
            let ccc = this.formatNum(params[2].data);

            return `
                <div>
                  <div style="border-bottom: 1px solid rgba(151, 151, 151, 0.2); padding-bottom: 8px; margin-bottom: 8px">
                    ${params[0].name}
                  </div>
                  <div><span style="color:#4AB5E8">${params[0].seriesName}: ${aaa}%</span><br/>
                  <span style="color:#EDBD88">${params[1].seriesName}: ${bbb}%</span><br/>
                   <span style="color:#FF5260">${params[2].seriesName}:${ccc}</span><br/>
                  
                </div>
              `;
          },
          padding: 20,
          textStyle: {
            fontSize: 12,
            color: "rgba(209, 212, 220, 1)", //设置文字颜色
          },
          tooltip: {
            show: true,
          },
          extraCssText: "background:rgba(61, 63, 71, 1);",
        },
        toolbox: {
          top: 20,
          right: "2%",
          iconStyle: {
            borderColor: "#D1D4DC",
          },
          emphasis: {
            iconStyle: {
              borderColor: "#edbd88", // 鼠标悬停时的图标颜色
            },
          },
          feature: {
            saveAsImage: {
              title: "Download Chart",
              icon: "path://M746.666667 597.333333a149.333333 149.333333 0 0 0-79.36 23.04l-123.093334-123.093333 333.013334-332.8a21.333333 21.333333 0 0 0 0-30.293333 21.333333 21.333333 0 0 0-30.08 0L514.133333 467.2 181.12 134.186667a21.333333 21.333333 0 0 0-30.08 0 21.333333 21.333333 0 0 0 0 30.293333l333.013333 332.8-124.8 124.8A147.626667 147.626667 0 0 0 277.333333 597.333333a149.333333 149.333333 0 1 0 113.92 53.12l122.88-122.88 120.533334 120.533334A149.333333 149.333333 0 1 0 746.666667 597.333333zM277.333333 853.333333a106.666667 106.666667 0 1 1 106.666667-106.666666 106.666667 106.666667 0 0 1-106.666667 106.666666z m469.333334 0a106.666667 106.666667 0 1 1 106.666666-106.666666 106.666667 106.666667 0 0 1-106.666666 106.666666z",
            },
            restore: {
              show: true,
              title: "Restore",
            },
            myFull: myToolFn.myFull(() => this.$refs.chart2.$el),
            myDownloadCSV: myToolFn.downloadCSV(() => [
              this.$refs.chart2.option.xAxis,
              ...this.$refs.chart2.option.series.map((v) => v.data),
            ]),
          },
        },
        grid: {
          left: "2%",
          right: "2%",
          bottom: "3%",
          top: 80,
          containLabel: true,
        },
        legend: {
          top: 20,
          textStyle: {
            fontSize: 12,
            color: "#D1D4DC",
          },
          padding: [5, 32, 0, 0],
          itemHeight: 4,
          itemWidth: 12,
          itemGap: 40,
          data: [
            {
              name: "ATM IV",
              icon: "rect",
            },
            {
              name: "FWD IV",
              icon: "rect",
            },
            {
              name: "交割日痛点",
              icon: "rect",
            },
          ],
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xData,
          //data: ['2022-09-13', '2022-09-12', '2022-09-11', '2022-09-10', '2022-09-09', '2022-09-08', '2022-09-07'],
          offset: 12,
          axisLabel: {
            textStyle: {
              color: "rgba(209, 212, 220, 1)",
            },
          },
        },
        yAxis: [
          {
            type: "value",
            min: "dataMin",
            name: "IV（%）",
            splitLine: {
              lineStyle: {
                type: "dashed",
                color: ["rgba(159, 159, 159, 0.1)"],
              },
            },
            axisLabel: {
              textStyle: {
                color: "rgba(209, 212, 220, 1)",
              },
              margin: 20,
            },
          },
          {
            type: "value",
            min: "dataMin",
            show: true,
            name: "交割日痛点",
            // interval: 5,
            axisLabel: {
              // formatter: function (v) {
              //   if (v > 1000 && v <= 1000000) {
              //     return v / 1000 + "K";
              //   } else if (v > 1000000 && v <= 1000000000) {
              //     return v / 1000000 + "M";
              //   } else if (v > 1000000000) {
              //     return v / 1000000000 + "B";
              //   } else {
              //     return v;
              //   }
              //   // return v + 'K';
              // },
              textStyle: {
                color: "rgba(209, 212, 220, 1)",
              },
              margin: 20,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "rgba(255, 255, 255, 0.2)",
              },
            },
          },
        ],
        series: [
          {
            name: "ATM IV",
            data: ayData,
            type: "line",
            symbol: "circle",
            showSymbol: false,
            // smooth: true,
            lineStyle: {
              color: "rgba(74, 181, 232, 1)",
              width: 1.5,
            },
            itemStyle: {
              normal: {
                color: "rgba(74, 181, 232, 1)",
              },
            },
          },
          {
            name: "FWD IV",
            data: byData,
            type: "line",
            symbol: "circle",
            showSymbol: false,
            // smooth: true,
            lineStyle: {
              color: "rgba(237, 189, 136, 1)",
              width: 1.5,
            },
            itemStyle: {
              normal: {
                color: "rgba(237, 189, 136, 1)",
              },
            },
          },
          {
            name: "交割日痛点",
            yAxisIndex: 1,
            data: cyData,
            type: "line",
            symbol: "circle",
            showSymbol: false,
            // smooth: true,
            lineStyle: {
              normal: {
                color: "rgba(255, 82, 96, 1)",
                type: "dashed",
                width: 1.5,
              },
            },
            itemStyle: {
              normal: {
                color: "rgba(255, 82, 96, 1)",
              },
            },
          },
        ],
        graphic: graphicBig("echarts-bg_yYgTFo1.png"),
      };
    },
    //对曲面数据按照"days"相同的值进行遍历重组，并返回新的数组对象

    processBBArray(bb) {
      const result = [];

      const dayMap = new Map();

      bb.forEach((item) => {
        const day = item.days;

        if (day === 0 || day === 1 || day === 2) {
          return;
        }

        if (!dayMap.has(day)) {
          dayMap.set(day, {
            day: day,
            list: [],
          });
        }

        dayMap.get(day).list.push({
          iv: item.iv,
          strike: item.price,
          ivDiff: item.ivDiff,
        });
      });

      dayMap.forEach((value) => {
        result.push(value);
      });

      return result;
    },
    //获取隐含波动率曲线全部数据
    getSurfaceData() {
      const params = {
        currency: this.radio,
      };
      this.axios
        .get("https://trade.rta-finance.com/trade/deribitiv/surface/", {
          params,
        })
        .then((res) => {
          if (res.data.code == "0") {
            this.isRepetition = false;
            let tempData = res.data.data;
            //console.log("隐含波动率", tempData);

            this.processedArray = this.processBBArray(tempData);
            this.ivResult = this.filterData(this.processedArray);
            console.log("以day新的数据", this.ivResult);
            this.priceArr = tempData.map((v) => v.price);
            this.ivArr = tempData.map((v) => v.iv);
            this.daysArr = tempData.map((v) => v.days);
            this.ivDiffArr = tempData.map((v) => v.ivDiff);
            //console.log("隐含波动率", this.priceArr, this.ivArr, this.daysArr);

            this.findIvSurface(this.ivResult);
            this.findFwdIvSurface();
          } else {
            this.priceArr = [];
            this.ivArr = [];
            this.daysArr = [];
            this.ivDiffArr = [];
          }
        })
        .catch((err) => {});
    },
    //取fwd_iv > 0包含本身以及前面的数据到新数组中
    // filterData(data) {
    //   let result = [];
    //   for (let i = 0; i < data.length; i++) {
    //     const currentItem = data[i];
    //     let foundIndex = -1;

    //     // 寻找当前项list数组中第一个满足条件的项的索引
    //     for (let j = 0; j < currentItem.list.length; j++) {
    //       if (currentItem.list[j].fwd_iv > 0) {
    //         foundIndex = j;
    //         break;
    //       }
    //     }

    //     if (foundIndex !== -1) {
    //       // 如果找到满足条件的项，则将该项及其之前的所有项添加到结果数组
    //       const selectedItems = currentItem.list.slice(0, foundIndex + 1);
    //       result.push({ ...currentItem, list: selectedItems });
    //     }
    //   }
    //   return result;
    // },

    //筛选出满足 iv < 61 条件的数据，并且只保留当前项以及前面所有项，但不包含最接近的那一项
    filterData(data) {
      let result = [];

      for (let i = 0; i < data.length; i++) {
        const currentDayData = data[i];
        const list = currentDayData.list;

        let nearestItem = null; // 用于记录最接近70的项

        for (let j = 0; j < list.length; j++) {
          const currentItem = list[j];

          if (currentItem.iv < 61) {
            if (
              !nearestItem ||
              Math.abs(currentItem.iv - 61) < Math.abs(nearestItem.iv - 610)
            ) {
              nearestItem = currentItem;
            }
          }
        }

        if (nearestItem) {
          // 保留当前项以及前面所有项，不包含最接近的那一项
          const newData = {
            day: currentDayData.day,
            list: list.slice(0, list.indexOf(nearestItem)),
          };

          result.push(newData);
        }
      }

      return result;
    },
    //筛选出满足 iv < 61 条件的数据，并且只保留当前项以及前面所有项，但不包含最接近的那一项，
    //但是后一个对象中保留 iv < 65 的所有项
    // filterData(data) {
    //   let result = [];

    //   for (let i = 0; i < data.length; i++) {
    //     const currentDayData = data[i];
    //     const list = currentDayData.list;

    //     let nearestItem = null; // 用于记录最接近70的项

    //     for (let j = 0; j < list.length; j++) {
    //       const currentItem = list[j];

    //       if (currentItem.iv < 61) {
    //         if (
    //           !nearestItem ||
    //           Math.abs(currentItem.iv - 61) < Math.abs(nearestItem.iv - 61)
    //         ) {
    //           nearestItem = currentItem;
    //         }
    //       }
    //     }

    //     if (nearestItem) {
    //       // 保留当前项以及前面所有项，不包含最接近的那一项
    //       const newData = {
    //         day: currentDayData.day,
    //         atm_index: currentDayData.atm_index,
    //         underlying_index: currentDayData.underlying_index,
    //         underlying_price: currentDayData.underlying_price,
    //         list: list.slice(0, list.indexOf(nearestItem)),
    //       };

    //       result.push(newData);
    //     }

    //     // 添加对最后一个对象的处理，保留 iv < 65 的所有项
    //     if (i === data.length - 1) {
    //       const lastDayData = {
    //         day: currentDayData.day,
    //         atm_index: currentDayData.atm_index,
    //         underlying_index: currentDayData.underlying_index,
    //         underlying_price: currentDayData.underlying_price,
    //         list: list.filter((item) => item.iv < 65),
    //       };

    //       result.push(lastDayData);
    //     }
    //   }

    //   return result;
    // },
    transformData(data) {
      let newData = [];

      for (let i = 0; i < data.length; i++) {
        const currentItem = data[i];
        const currentDay = currentItem.day;

        for (let j = 0; j < currentItem.list.length; j++) {
          const currentListItem = currentItem.list[j];
          const currentStrike = currentListItem.strike;
          const currentIV = currentListItem.iv;

          newData.push([currentDay, currentStrike, currentIV]);
        }
      }

      return newData;
    },

    //渲染隐含波动率iv曲面
    findIvSurface() {
      const transformedData = this.transformData(this.ivResult);
      //console.log("result", transformedData);
      // console.log("隐含波动率iv曲面数据", this.radio);

      // var x = this.daysArr;
      // var y = this.priceArr;
      // var z = this.ivArr;
      // var data = [];
      // for (var i = 0; i < x.length; i++) {
      //   data.push([x[i], y[i], z[i]]);
      // }
      // // console.log("data", data);
      this.ivOption = {
        backgroundColor: "#3d3f47",
        tooltip: {
          borderWidth: "0",
          borderColor: "none",
          padding: 20,
          textStyle: {
            fontSize: 12,
            color: "rgba(209, 212, 220, 1)", //设置文字颜色
          },
          tooltip: {
            show: true,
          },
          extraCssText: "background:rgba(61, 63, 71, 1);",
          trigger: "item",
          formatter: function (params) {
            // let aaa = this.kmb(params.data[1]);
            return (
              "Days : " +
              params.value[0] +
              "<br>Strike : " +
              params.data[1] +
              "<br>IV : " +
              params.value[2] +
              "%"
            );
          },
        },
        toolbox: {
          top: 20,
          right: "2%",
          iconStyle: {
            borderColor: "#D1D4DC",
          },
          emphasis: {
            iconStyle: {
              borderColor: "#edbd88", // 鼠标悬停时的图标颜色
            },
          },
          feature: {
            saveAsImage: {
              title: "Download Chart",
              icon: "path://M746.666667 597.333333a149.333333 149.333333 0 0 0-79.36 23.04l-123.093334-123.093333 333.013334-332.8a21.333333 21.333333 0 0 0 0-30.293333 21.333333 21.333333 0 0 0-30.08 0L514.133333 467.2 181.12 134.186667a21.333333 21.333333 0 0 0-30.08 0 21.333333 21.333333 0 0 0 0 30.293333l333.013333 332.8-124.8 124.8A147.626667 147.626667 0 0 0 277.333333 597.333333a149.333333 149.333333 0 1 0 113.92 53.12l122.88-122.88 120.533334 120.533334A149.333333 149.333333 0 1 0 746.666667 597.333333zM277.333333 853.333333a106.666667 106.666667 0 1 1 106.666667-106.666666 106.666667 106.666667 0 0 1-106.666667 106.666666z m469.333334 0a106.666667 106.666667 0 1 1 106.666666-106.666666 106.666667 106.666667 0 0 1-106.666666 106.666666z",
            },
            restore: {
              show: true,
              title: "Restore",
            },
            myFull: myToolFn.myFull(() => this.$refs.chart3.$el),
            myDownloadCSV: myToolFn.downloadCSV(
              () => this.$refs.chart3.option.series[0].data,
              false
            ),
          },
        },
        visualMap: {
          show: !1,
          dimension: 2,
          min: 50,
          max: 150,

          inRange: {
            color: [
              "#313695",
              "#4575b4",
              "#74add1",
              "#abd9e9",
              "#e0f3f8",
              "#ffffbf",
              "#fee090",
              "#fdae61",
              "#f46d43",
              "#d73027",
              "#a50026",
            ],
          },
        },
        xAxis3D: {
          type: "value",
          name: "Days",
          inverse: true,
          boundaryGap: false,
          axisLabel3D: {
            interval: 50, // 设置刻度间隔
          },
          axisLabel: {
            textStyle: {
              color: "#D1D4DC",
              fontSize: 12,
            },
          },
          axisLine: {
            lineStyle: {
              color: "#D1D4DC",
              width: 1,
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(255, 255, 255, 0.2)",
              width: 1,
              type: "solid",
            },
          },
          splitArea: {
            show: true,
            areaStyle: {
              color: ["rgba(61, 63, 71, 1)", "rgba(61, 63, 71, 1)"],
              shadowColor: "#D1D4DC",
              opacity: 1,
            },
          },
        },
        yAxis3D: {
          type: "value",
          name: "Strike Price",
          scale: true,
          min: this.radio === "BTC" ? 25000 : undefined,
          max: this.radio === "BTC" ? 50000 : undefined,
          boundaryGap: false,
          axisLabel: {
            textStyle: {
              color: "#D1D4DC",
              fontSize: 12,
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#D1D4DC",
              width: 1,
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(255, 255, 255, 0.2)",
              width: 1,
              type: "solid",
            },
          },
          splitArea: {
            show: true,
            areaStyle: {
              color: ["rgba(61, 63, 71, 1)", "rgba(61, 63, 71, 1)"],
              shadowColor: "#D1D4DC",
              opacity: 1,
            },
          },
        },
        zAxis3D: {
          type: "value",
          name: "IV",
          scale: true,
          boundaryGap: false,
          axisLabel: {
            textStyle: {
              color: "#D1D4DC",
              fontSize: 12,
            },
          },
          axisLine: {
            lineStyle: {
              color: "#D1D4DC",
              width: 1,
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(255, 255, 255, 0.2)",
              width: 1,
              type: "solid",
            },
          },
          splitArea: {
            show: true,
            areaStyle: {
              color: ["rgba(61, 63, 71, 1)", "rgba(61, 63, 71, 1)"],
              shadowColor: "#D1D4DC",
              opacity: 1,
            },
          },
        },
        grid3D: {
          boxWidth: 100,
          boxHeight: 100,
          axisPointer: {
            show: true,
          },
        },
        series: [
          {
            type: "surface",
            smooth: true,
            gridSize: 20,
            wireframe: {
              show: true,
              lineStyle: {
                color: "black", // 设置线框为黑色
                width: 1,
              },
            },
            data: transformedData,
            itemStyle: {
              doubleSide: true, // 双面显示
              border: {
                smooth: 1, // 调整平滑度，值在 0 到 1 之间
                lineStyle: {
                  color: "black", // 设置线的颜色
                  width: 1, // 设置线的宽度
                },
              },
            },
            shading: "color",
          },
        ],

        graphic: graphic("echarts-bg_yYgTFo1.png"),
      };
    },
    //渲染ATM IV和FWD.IV差值曲线
    findFwdIvSurface() {
      var x = this.daysArr;
      var y = this.priceArr;
      var z = this.ivDiffArr;
      var data = [];
      for (var i = 0; i < x.length; i++) {
        data.push([x[i].toFixed(2), y[i].toFixed(2), z[i].toFixed(2)]);
      }
      //console.log("FWD.IV", data);
      const fwdResult = this.filterData(this.processedArray);
      //console.log("FWD.IV", fwdResult);
      let newFwdData = [];

      //console.log("渲染ATM IV和FWD.IV差值曲线", newFwdData);

      this.ivDiffOption = {
        backgroundColor: "#3d3f47",
        tooltip: {
          borderWidth: "0",
          borderColor: "none",
          padding: 20,
          textStyle: {
            fontSize: 12,
            color: "rgba(209, 212, 220, 1)", //设置文字颜色
          },
          tooltip: {
            show: true,
          },
          extraCssText: "background:rgba(61, 63, 71, 1);",
          trigger: "item",
          formatter: function (params) {
            // let aaa = this.kmb(params.data[1]);
            return (
              "Days : " +
              params.value[0] +
              "<br>Strike : " +
              params.data[1] +
              "<br>IV : " +
              params.value[2] +
              "%"
            );
          },
        },
        toolbox: {
          top: 20,
          right: "2%",
          iconStyle: {
            borderColor: "#D1D4DC",
          },
          emphasis: {
            iconStyle: {
              borderColor: "#edbd88", // 鼠标悬停时的图标颜色
            },
          },
          feature: {
            saveAsImage: {
              title: "Download Chart",
              icon: "path://M746.666667 597.333333a149.333333 149.333333 0 0 0-79.36 23.04l-123.093334-123.093333 333.013334-332.8a21.333333 21.333333 0 0 0 0-30.293333 21.333333 21.333333 0 0 0-30.08 0L514.133333 467.2 181.12 134.186667a21.333333 21.333333 0 0 0-30.08 0 21.333333 21.333333 0 0 0 0 30.293333l333.013333 332.8-124.8 124.8A147.626667 147.626667 0 0 0 277.333333 597.333333a149.333333 149.333333 0 1 0 113.92 53.12l122.88-122.88 120.533334 120.533334A149.333333 149.333333 0 1 0 746.666667 597.333333zM277.333333 853.333333a106.666667 106.666667 0 1 1 106.666667-106.666666 106.666667 106.666667 0 0 1-106.666667 106.666666z m469.333334 0a106.666667 106.666667 0 1 1 106.666666-106.666666 106.666667 106.666667 0 0 1-106.666666 106.666666z",
            },
            restore: {
              show: true,
              title: "Restore",
            },
            myFull: myToolFn.myFull(() => this.$refs.chart4.$el),
            myDownloadCSV: myToolFn.downloadCSV(
              () => this.$refs.chart4.option.series[0].data,
              false
            ),
          },
        },
        visualMap: {
          show: !1,
          dimension: 2,
          min: 50,
          max: 150,

          inRange: {
            color: [
              "#313695",
              "#4575b4",
              "#74add1",
              "#abd9e9",
              "#e0f3f8",
              "#ffffbf",
              "#fee090",
              "#fdae61",
              "#f46d43",
              "#d73027",
              "#a50026",
            ],
          },
        },
        xAxis3D: {
          type: "value",
          name: "Days",
          inverse: true,
          boundaryGap: false,
          axisLabel3D: {
            interval: 50, // 设置刻度间隔
          },
          axisLabel: {
            textStyle: {
              color: "#D1D4DC",
              fontSize: 12,
            },
          },
          axisLine: {
            lineStyle: {
              color: "#D1D4DC",
              width: 1,
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(255, 255, 255, 0.2)",
              width: 1,
              type: "solid",
            },
          },
          splitArea: {
            show: true,
            areaStyle: {
              color: ["rgba(61, 63, 71, 1)", "rgba(61, 63, 71, 1)"],
              shadowColor: "#D1D4DC",
              opacity: 1,
            },
          },
        },
        yAxis3D: {
          type: "value",
          name: "Strike Price",
          scale: true,
          boundaryGap: false,
          axisLabel: {
            textStyle: {
              color: "#D1D4DC",
              fontSize: 12,
            },
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "#D1D4DC",
              width: 1,
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(255, 255, 255, 0.2)",
              width: 1,
              type: "solid",
            },
          },
          splitArea: {
            show: true,
            areaStyle: {
              color: ["rgba(61, 63, 71, 1)", "rgba(61, 63, 71, 1)"],
              shadowColor: "#D1D4DC",
              opacity: 1,
            },
          },
        },
        zAxis3D: {
          type: "value",
          name: "ATM IV和FWD.IV差值",
          scale: true,
          boundaryGap: false,
          axisLabel: {
            nameLocation: "end", // 将名称放在轴线末端
            nameGap: 15, // 名称与轴线之间的距离
            textStyle: {
              color: "#D1D4DC",
              fontSize: 12,
              rotate: 360,
            },
          },
          axisLine: {
            lineStyle: {
              color: "#D1D4DC",
              width: 1,
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "rgba(255, 255, 255, 0.2)",
              width: 1,
              type: "solid",
            },
          },
          splitArea: {
            show: true,
            areaStyle: {
              color: ["rgba(61, 63, 71, 1)", "rgba(61, 63, 71, 1)"],
              shadowColor: "#D1D4DC",
              opacity: 1,
            },
          },
        },
        grid3D: {
          boxWidth: 100,
          boxHeight: 100,
          axisPointer: {
            show: true,
          },
        },
        series: [
          {
            type: "surface",
            smooth: true,
            gridSize: 20,
            wireframe: {
              show: true,
              lineStyle: {
                color: "black", // 设置线框为黑色
                width: 1,
              },
            },

            data: data,
          },
        ],

        graphic: graphic("echarts-bg_yYgTFo1.png"),
      };
    },
    findAtmSurface() {},
    //获取skew近期一周数据
    getOneSkew() {
      const params = {
        currency: this.radio,
        monthType: "one",
      };
      this.axios
        .get("https://trade.rta-finance.com/trade/deribitiv/skew/", {
          params,
        })
        .then((res) => {
          if (res.data.code == "0") {
            this.isRepetition = false;
            let tempData = res.data.data;
            //console.log("隐含波动率", tempData);
            this.oneUnderlyingIndex = tempData[0].underlying_index;
            this.oneStrike = tempData.map((v) => v.price);
            this.oneIv = tempData.map((v) => v.iv);
            this.oneDelta = tempData.map((v) => v.delta);
            // console.log("skew近期一周", tempData, this.oneUnderlyingIndex);

            this.findIvSkew();
          } else {
            this.oneStrike = [];
            this.oneIv = [];
            this.oneDelta = [];
          }
        })
        .catch((err) => {});
    },
    findIvSkew() {
      const xData = this.oneStrike;
      const ayData = this.oneIv;
      const byData = this.oneDelta;
      if (xData == true) {
        //暂无数据
        this.ivSlewOption = NOT_DATA_OPTION;
      } else {
        this.ivSlewOption = {
          ackgroundColor: "#3d3f47",
          color: "rgba(0, 174, 147, 1)",
          tooltip: {
            trigger: "axis",
            borderWidth: "0", //边框宽度设置1
            borderColor: "none", //设置边框颜色
            formatter: (params) => {
              let aaa = this.kmb(params[0].data);
              let bbb = this.kmb(params[1].data);
              let strike = this.kmb(params[0].name);
              return `
                <div>
                  <div >
                    Strike : ${strike}
                  </div>
                  <div><span style="color:#4AB5E8">${params[0].seriesName} : ${aaa} %</span><br/>
                   <span style="color:#EDBD88">${params[1].seriesName} : ${bbb}</span><br/></div>
                  
              `;
            },
            padding: 20,
            textStyle: {
              fontSize: 12,
              color: "rgba(209, 212, 220, 1)", //设置文字颜色
            },
            tooltip: {
              show: true,
            },
            extraCssText: "background:rgba(61, 63, 71, 1);",
          },
          toolbox: {
            top: 20,
            right: "2%",
            iconStyle: {
              borderColor: "#D1D4DC",
            },
            emphasis: {
              iconStyle: {
                borderColor: "#edbd88", // 鼠标悬停时的图标颜色
              },
            },
            feature: {
              saveAsImage: {
                title: "Download Chart",
                icon: "path://M746.666667 597.333333a149.333333 149.333333 0 0 0-79.36 23.04l-123.093334-123.093333 333.013334-332.8a21.333333 21.333333 0 0 0 0-30.293333 21.333333 21.333333 0 0 0-30.08 0L514.133333 467.2 181.12 134.186667a21.333333 21.333333 0 0 0-30.08 0 21.333333 21.333333 0 0 0 0 30.293333l333.013333 332.8-124.8 124.8A147.626667 147.626667 0 0 0 277.333333 597.333333a149.333333 149.333333 0 1 0 113.92 53.12l122.88-122.88 120.533334 120.533334A149.333333 149.333333 0 1 0 746.666667 597.333333zM277.333333 853.333333a106.666667 106.666667 0 1 1 106.666667-106.666666 106.666667 106.666667 0 0 1-106.666667 106.666666z m469.333334 0a106.666667 106.666667 0 1 1 106.666666-106.666666 106.666667 106.666667 0 0 1-106.666666 106.666666z",
              },
              restore: {
                show: true,
                title: "Restore",
              },
              myFull: myToolFn.myFull(() => this.$refs.chart5.$el),
              myDownloadCSV: myToolFn.downloadCSV(() => [
                this.$refs.chart5.option.xAxis,
                ...this.$refs.chart5.option.series.map((v) => v.data),
              ]),
            },
          },
          grid: {
            left: "2%",
            right: "2%",
            bottom: "7%",
            top: 80,
            containLabel: true,
          },
          legend: {
            top: 20,
            textStyle: {
              fontSize: 12,
              color: "#D1D4DC",
            },
            padding: [0, 32, 0, 0],
            itemHeight: 4,
            itemWidth: 12,
            data: [
              {
                name: "IV",
                icon: "rect",
              },
              {
                name: "Delta",
                icon: "rect",
              },
            ],
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: xData,
            //data: ['2022-09-13', '2022-09-12', '2022-09-11', '2022-09-10', '2022-09-09', '2022-09-08', '2022-09-07'],
            offset: 12,
            axisLabel: {
              formatter: function (v) {
                if (v > 1000 && v <= 1000000) {
                  return v / 1000 + "K";
                } else if (v > 1000000 && v <= 1000000000) {
                  return v / 1000000 + "M";
                } else if (v > 1000000000) {
                  return v / 1000000000 + "B";
                } else {
                  return v;
                }
                // return v + 'K';
              },
              textStyle: {
                color: "rgba(209, 212, 220, 1)",
              },
            },
          },
          yAxis: [
            {
              type: "value",
              name: "IV（%）",
              splitLine: {
                lineStyle: {
                  type: "dashed",
                  color: ["rgba(159, 159, 159, 0.1)"],
                },
              },
              axisLabel: {
                textStyle: {
                  color: "rgba(209, 212, 220, 1)",
                },
                margin: 20,
              },
            },
            {
              type: "value",
              show: true,
              name: "Delta",
              // interval: 5,
              axisLabel: {
                textStyle: {
                  color: "rgba(209, 212, 220, 1)",
                },
                margin: 20,
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                  color: "rgba(255, 255, 255, 0.2)",
                },
              },
            },
          ],
          series: [
            {
              name: "IV",
              data: ayData,
              type: "line",
              symbol: "circle",
              showSymbol: false,
              lineStyle: {
                color: "rgba(74, 181, 232, 1)",
                width: 1.5,
              },
              itemStyle: {
                normal: {
                  color: "rgba(74, 181, 232, 1)",
                },
              },
            },
            {
              name: "Delta",
              data: byData,
              type: "line",
              yAxisIndex: 1,
              symbol: "circle",
              showSymbol: false,
              lineStyle: {
                normal: {
                  color: "rgba(237, 189, 136, 1)",
                  type: "dashed",
                  width: 1.5,
                },
              },
              itemStyle: {
                normal: {
                  color: "rgba(237, 189, 136, 1)",
                },
              },
            },
          ],
          graphic: [
            {
              type: "image",
              id: "logo",
              right: "center",
              bottom: "center",
              z: 0,
              bounding: "all",
              style: {
                image: "echarts-bg_yYgTFo1.png", // 必须是https开头的图片路径地址
                width: 400,
                height: 68,
              },
            },
          ],
        };
      }
    },
    //获取skew近期一周数据
    getThreeSkew() {
      const params = {
        currency: this.radio,
        monthType: "three",
      };
      this.axios
        .get("https://trade.rta-finance.com/trade/deribitiv/skew/", {
          params,
        })
        .then((res) => {
          if (res.data.code == "0") {
            this.isRepetition = false;
            let tempData = res.data.data;
            //console.log("隐含波动率", tempData);
            if (tempData.length != 0) {
              this.threeUnderlyingIndex = tempData[0].underlying_index;
              this.threeStrike = tempData.map((v) => v.price);
              this.threeIv = tempData.map((v) => v.iv);
              this.threeDelta = tempData.map((v) => v.delta);
              // console.log(
              //   "——（中期（3 month））",
              //   tempData,
              //   this.oneUnderlyingIndex
              // );
            } else {
              this.threeStrike = [];
              this.threeIv = [];
              this.threeDelta = [];
            }

            this.findthreeIvSkew();
          } else {
            this.threeStrike = [];
            this.threeIv = [];
            this.threeDelta = [];
          }
        })
        .catch((err) => {});
    },
    findthreeIvSkew() {
      const xData = this.threeStrike;
      const ayData = this.threeIv;
      const byData = this.threeDelta;
      if (xData == true) {
        //暂无数据
        this.threeIvSlewOption = NOT_DATA_OPTION;
      } else {
        this.threeIvSlewOption = {
          backgroundColor: "#3d3f47",
          color: "rgba(0, 174, 147, 1)",
          tooltip: {
            trigger: "axis",
            borderWidth: "0", //边框宽度设置1
            borderColor: "none", //设置边框颜色
            // formatter: (params) => {
            //   let strike = this.kmb(params[0].name);
            //   let result = `<div >
            //        Strike: ${strike}
            //       </div>`;
            //   params.forEach((item) => {
            //     var re = item.seriesName;
            //     var colors = "";

            //     switch (re) {
            //       case "Strike":
            //         colors = "#D1D4DC";
            //         break;
            //       case "IV":
            //         colors = "#4AB5E8";
            //         break;
            //       case "Delta":
            //         colors = "#EDBD88";
            //         break;
            //     }
            //     var aaa = this.formatNum(item.data.toFixed(2));
            //     result += `
            //       <span style="color:${colors} ;">${item.seriesName} : ${aaa}</span><br/>  `;
            //   });
            //   return result;
            // },
            formatter: (params) => {
              let aaa = this.kmb(params[0].data);
              let bbb = this.kmb(params[1].data);
              let strike = this.kmb(params[0].name);
              return `
                <div>
                  <div >
                    Strike : ${strike}
                  </div>
                  <div><span style="color:#4AB5E8">${params[0].seriesName} : ${aaa} %</span><br/>
                   <span style="color:#EDBD88">${params[1].seriesName} : ${bbb}</span><br/></div>
                  
              `;
            },
            padding: 20,
            textStyle: {
              fontSize: 12,
              color: "rgba(209, 212, 220, 1)", //设置文字颜色
            },
            tooltip: {
              show: true,
            },
            extraCssText: "background:rgba(61, 63, 71, 1);",
          },
          toolbox: {
            top: 20,
            right: "2%",
            iconStyle: {
              borderColor: "#D1D4DC",
            },
            emphasis: {
              iconStyle: {
                borderColor: "#edbd88", // 鼠标悬停时的图标颜色
              },
            },
            feature: {
              saveAsImage: {
                title: "Download Chart",
                icon: "path://M746.666667 597.333333a149.333333 149.333333 0 0 0-79.36 23.04l-123.093334-123.093333 333.013334-332.8a21.333333 21.333333 0 0 0 0-30.293333 21.333333 21.333333 0 0 0-30.08 0L514.133333 467.2 181.12 134.186667a21.333333 21.333333 0 0 0-30.08 0 21.333333 21.333333 0 0 0 0 30.293333l333.013333 332.8-124.8 124.8A147.626667 147.626667 0 0 0 277.333333 597.333333a149.333333 149.333333 0 1 0 113.92 53.12l122.88-122.88 120.533334 120.533334A149.333333 149.333333 0 1 0 746.666667 597.333333zM277.333333 853.333333a106.666667 106.666667 0 1 1 106.666667-106.666666 106.666667 106.666667 0 0 1-106.666667 106.666666z m469.333334 0a106.666667 106.666667 0 1 1 106.666666-106.666666 106.666667 106.666667 0 0 1-106.666666 106.666666z",
              },
              restore: {
                show: true,
                title: "Restore",
              },
              myFull: myToolFn.myFull(() => this.$refs.chart6.$el),
              myDownloadCSV: myToolFn.downloadCSV(() => [
                this.$refs.chart6.option.xAxis,
                ...this.$refs.chart6.option.series.map((v) => v.data),
              ]),
            },
          },
          grid: {
            left: "2%",
            right: "2%",
            bottom: "7%",
            top: 80,
            containLabel: true,
          },
          legend: {
            top: 20,
            textStyle: {
              fontSize: 12,
              color: "#D1D4DC",
            },
            padding: [0, 32, 0, 0],
            itemHeight: 4,
            itemWidth: 12,
            data: [
              {
                name: "IV",
                icon: "rect",
              },
              {
                name: "Delta",
                icon: "rect",
              },
            ],
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: xData,
            //data: ['2022-09-13', '2022-09-12', '2022-09-11', '2022-09-10', '2022-09-09', '2022-09-08', '2022-09-07'],
            offset: 12,
            axisLabel: {
              formatter: function (v) {
                if (v > 1000 && v <= 1000000) {
                  return v / 1000 + "K";
                } else if (v > 1000000 && v <= 1000000000) {
                  return v / 1000000 + "M";
                } else if (v > 1000000000) {
                  return v / 1000000000 + "B";
                } else {
                  return v;
                }
                // return v + 'K';
              },
              textStyle: {
                color: "rgba(209, 212, 220, 1)",
              },
            },
          },
          yAxis: [
            {
              type: "value",
              name: "IV（%）",
              splitLine: {
                lineStyle: {
                  type: "dashed",
                  color: ["rgba(159, 159, 159, 0.1)"],
                },
              },
              axisLabel: {
                textStyle: {
                  color: "rgba(209, 212, 220, 1)",
                },
                margin: 20,
              },
            },
            {
              type: "value",
              show: true,
              name: "Delta",
              // interval: 5,
              axisLabel: {
                textStyle: {
                  color: "rgba(209, 212, 220, 1)",
                },
                margin: 20,
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                  color: "rgba(255, 255, 255, 0.2)",
                },
              },
            },
          ],
          series: [
            {
              name: "IV",
              data: ayData,
              type: "line",
              symbol: "circle",
              showSymbol: false,
              lineStyle: {
                color: "rgba(74, 181, 232, 1)",
                width: 1.5,
              },
              itemStyle: {
                normal: {
                  color: "rgba(74, 181, 232, 1)",
                },
              },
            },
            {
              name: "Delta",
              data: byData,
              type: "line",
              yAxisIndex: 1,
              symbol: "circle",
              showSymbol: false,
              lineStyle: {
                normal: {
                  color: "rgba(237, 189, 136, 1)",
                  type: "dashed",
                  width: 1.5,
                },
              },
              itemStyle: {
                normal: {
                  color: "rgba(237, 189, 136, 1)",
                },
              },
            },
          ],
          graphic: [
            {
              type: "image",
              id: "logo",
              right: "center",
              bottom: "center",
              z: 0,
              bounding: "all",
              style: {
                image: "echarts-bg_yYgTFo1.png", // 必须是https开头的图片路径地址
                width: 400,
                height: 68,
              },
            },
          ],
        };
      }
    },
  },
};
</script>
<style lang="scss" >
.currency-btn {
  margin-bottom: 2rem;
  width: 100%;
  border-bottom: 1px solid rgba(239, 195, 148, 0.3);
  .el-radio-group {
    .el-radio-button {
      margin: 0 32px 0 0;
      .el-radio-button__inner {
        background: transparent !important;
        border: none;
        height: 44px;
        line-height: 44px;
        padding: 0 !important;
        font-size: 20px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #d1d4dc;
        border-radius: 0 !important;
      }
      .el-radio-button__inner:focus {
        border-bottom: 2px solid #edbd88;
        color: #edbd88;
      }
      .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        border-bottom: 2px solid #edbd88;
        color: #edbd88;
      }
    }
  }
}
@import "./scss/option.scss";
</style>