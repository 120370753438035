<template>
  <div class="position-data">
    <div class="currency-btn">
      <el-radio-group v-model="radio">
        <el-radio-button label="BTC"></el-radio-button>
        <el-radio-button label="ETH"></el-radio-button>
      </el-radio-group>
    </div>
    <b-card no-body>
      <b-card-header>
        <b-card-title class="mb-50"
          ><div class="header-title">期权成交量</div>
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <b-row class="">
          <b-col lg="12">
            <div class="container-body-distribution" style="height: 100%">
              <div class="container-body-title">
                <a
                  href="https://lib.rta-finance.com/#/article?id=625"
                  target="_blank"
                  >按天</a
                >

                <span>（Volume (Previous day))</span>
              </div>
              <div class="select-container">
                <div class="chart-option filter">
                  <el-select
                    v-model="interestArr.day"
                    placeholder=""
                    class="previos-day"
                    @change="timeChange($event)"
                  >
                    <el-option
                      v-for="item in dayOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="charts" style="margin-top: 10px">
                <Chart
                  ref="chart"
                  class="chart"
                  :option="strikesOption"
                  :loading="loading"
                />
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="">
          <b-col lg="6">
            <div class="container-body-distribution">
              <div class="container-body-title">
                <a
                  href="https://lib.rta-finance.com/#/article?id=626"
                  target="_blank"
                  >按到期日</a
                >

                <span>（Volume By Expiration (Current day)）</span>
              </div>
              <div class="charts">
                <Chart
                  ref="chart1"
                  :option="expirationOption"
                  :height="yy"
                  :loading="loading1"
                />
              </div>
            </div>
          </b-col>

          <b-col lg="6">
            <div class="container-body-distribution">
              <div class="container-body-title">
                <a
                  href="https://lib.rta-finance.com/#/article?id=627"
                  target="_blank"
                  >最大成交量</a
                >

                <span>（Top Volume By Instrument (Current Day)）</span>
              </div>
              <div class="select-container">
                <div class="chart-option filter">
                  <el-select
                    v-model="PainPoints.data"
                    placeholder=""
                    @change="maxDateChange($event)"
                  >
                    <el-option
                      v-for="item in dataOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="charts">
                <Chart
                  ref="chart2"
                  class="chart"
                  :option="currentDayOption"
                  :height="aa"
                  :loading="loading2"
                />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <b-card-title class="mb-50"
          ><div class="header-title">
            <a
              href="https://lib.rta-finance.com/#/article?id=628"
              target="_blank"
              >比特币期权成交量</a
            >

            <span>（{{ radio }} Option Trade Volume）</span>
          </div>
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <div class="select-container">
          <div class="chart-option filter" style="margin-top: 16px">
            <el-select
              @change="klineChange($event)"
              v-model="PainPoints.timeType"
              placeholder=""
            >
              <el-option
                v-for="item in timeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="container-body">
          <Chart
            ref="chart3"
            class="chart"
            :option="btcOption"
            :loading="loading3"
          />
        </div>
      </b-card-body>
    </b-card>
    <b-card no-body>
      <b-card-header>
        <b-card-title class="mb-50"
          ><div class="header-title">
            <a
              href="https://lib.rta-finance.com/#/article?id=629"
              target="_blank"
              >常规交易和大宗交易</a
            >

            <span>（Regular Trades 、 Block Trades）</span>
          </div>
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <b-row style="padding: 0 12px">
          <b-col lg="4">
            <div class="container-body-distribution">
              <div class="container-body-title pie-body-title">
                常规交易vs大宗交易
                <span>（Regular Trades vs Block Trades）</span>
              </div>
              <div class="select-container">
                <!-- <div class="chart-option filter">
                  <el-select v-model="interestArr.prev" placeholder="">
                    <el-option
                      v-for="item in valueOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div> -->
                <div class="chart-option filter">
                  <el-select
                    @change="TradesChange($event)"
                    v-model="PainPoints.blockTime"
                    placeholder=""
                  >
                    <el-option
                      v-for="item in blockOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="charts">
                <Chart
                  ref="chart4"
                  class="chart"
                  :option="tradeOption"
                  :height="height"
                  :loading="loading4"
                />
              </div>
            </div>
          </b-col>
          <b-col lg="4">
            <div class="container-body-distribution">
              <div class="container-body-title pie-second-body-title">
                常规交易
                <span>（Regular Trade Breakdown）</span>
              </div>
              <div class="select-container">
                <!-- <div class="chart-option filter">
                  <el-select   v-model="interestArr.prev" placeholder="">
                    <el-option
                      v-for="item in valueOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div> -->
                <div class="chart-option filter">
                  <el-select
                    @change="regularChange($event)"
                    v-model="PainPoints.regularDate"
                    placeholder=""
                  >
                    <el-option
                      v-for="item in regularOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="charts">
                <Chart
                  ref="chart5"
                  class="chart"
                  :option="blockTradeOption"
                  :height="height"
                  :loading="loading5"
                />
              </div>
            </div>
          </b-col>
          <b-col lg="4" style="padding-right: 0">
            <div class="container-body-distribution">
              <div class="container-body-title pie-third-body-title">
                大宗交易
                <span>（Block Trade Breakdown）</span>
              </div>
              <div class="select-container">
                <!-- <div class="chart-option filter">
                  <el-select v-model="interestArr.prev" placeholder="">
                    <el-option
                      v-for="item in valueOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div> -->
                <div class="chart-option filter">
                  <el-select
                    @change="brockChange($event)"
                    v-model="PainPoints.blockDate"
                    placeholder=""
                  >
                    <el-option
                      v-for="item in bROptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="charts">
                <Chart
                  ref="chart6"
                  class="chart"
                  :option="topVolumeOption"
                  :height="height"
                  :loading="loading6"
                />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
import * as myToolFn from "@/utils/echartsFullScreen";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
} from "bootstrap-vue";
import {
  fromatterPieOptions,
  number2String,
  graphic,
  graphicBig,
  SPLIT_LINE,
  NOT_DATA_OPTION,
} from "../../../utils/chart";
import Chart from "../../charts-and-maps/charts/chart.vue";
import BarCharts from "../../charts-and-maps/charts/BarChart.vue";
import CustomizedPie from "@/views/charts-and-maps/charts/echart/option-echart/CustomizedPie.vue";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    Chart,
    BarCharts,
    CustomizedPie,
  },
  props: {
    // radio: { type: String },
    // name: { type: String },
    // activeNameTab: {
    //   type: String,
    //   required: true,
    // },
  }, //接收
  porps: ["option", "height"],
  data() {
    return {
      radio: "BTC",
      name: "a",
      activeNameTab: "a",
      PainPoints: {
        min_price: "",
        max_price: "",
        data: "day",
        timeType: "d",
        blockTime: "seven",
        regularDate: "seven",
        blockDate: "seven",
      },
      blockOptions: [
        {
          value: "one",
          label: "一天",
        },
        {
          value: "seven",
          label: "七天",
        },
      ],
      regularOptions: [
        {
          value: "one",
          label: "一天",
        },
        {
          value: "seven",
          label: "七天",
        },
      ],
      bROptions: [
        {
          value: "one",
          label: "一天",
        },
        {
          value: "seven",
          label: "七天",
        },
      ],
      regularOptions: [
        {
          value: "one",
          label: "一天",
        },
        {
          value: "seven",
          label: "七天",
        },
      ],
      timeOptions: [
        {
          value: "1h",
          label: "一小时",
        },
        {
          value: "4h",
          label: "四小时",
        },

        {
          value: "d",
          label: "一天",
        },
      ],
      dataOptions: [
        {
          value: "day",
          label: "日",
        },
        {
          value: "week",
          label: "周",
        },
      ],
      aa: "400px",
      yy: "443px",
      height: "356px",
      earningOption: {},
      expirationOption: {},
      currentDayOption: {},
      strikesOption: {},

      tradeOption: {},
      blockTradeOption: {},
      topVolumeOption: {},
      valueOptions: [
        {
          value: "Contracts",
          label: "Contracts",
        },
        {
          value: "$ value",
          label: "$ value",
        },
      ],
      dayOptions: [
        {
          value: "month",
          label: "月",
        },
        {
          value: "quarter",
          label: "季度",
        },
        {
          value: "halfYear",
          label: "半年",
        },
        {
          value: "year",
          label: "一年",
        },
      ],
      interestArr: {
        prev: "Contracts",
        day: "month",
      },
      btcOption: {},
      //按天
      dateArr: [],
      monthArr: [],
      weekArr: [],
      dayArr: [],
      //按到期日
      callArr: [],
      putArr: [],
      callValues: [],
      putValues: [],
      byExpirationX: [],
      //最大成交量
      instrumentArr: [],
      amountCountArr: [],
      amountValueArr: [],
      //期权成交量
      kk: [],
      buyK: [],
      sellK: [],
      totalK: [],
      dateK: [],
      //常规交易vs大宗交易
      blockTrade: "",
      regularTrade: "",
      //常规交易
      regularBuyCall: "",
      regularBuyPut: "",
      regularSellCall: "",
      regularSellPut: "",

      //大宗交易
      brockBuyCall: "",
      brockBuyPut: "",
      brockSellCall: "",
      brockSellPut: "",
      loading: true,
      loading1: true,
      loading2: true,
      loading3: true,
      loading4: true,
      loading5: true,
      loading6: true,
    };
  },
  watch: {
    activeNameTab(newValue) {
      this.getListDataPageAction(this.name, newValue, this.radio);
    },
    radio: {
      handler(newValue) {
        this.getListDataPageAction(this.name, this.activeNameTab, newValue);
      },
      immediate: true,
    },
  },
  created() {},
  computed: {},
  mounted() {},
  methods: {
    //判断一下只执行当前选中的标签页
    getListDataPageAction(name, activeNameTab, radio) {
      if (name === activeNameTab && !this.isRepetition) {
        this.isRepetition = true;
        this.getByPreviousData(radio);
        this.getByExpirationData(radio);
        this.getByInstrumentData(radio);
        this.getKlinData(radio);
        this.getBlockTrades(radio);
        this.getRegularData(radio);
        this.getBrockData(radio);
        this.totalK = [];
        this.buyK = [];
        this.sellK = [];
        this.dateK = [];
        this.kk = [];
      }
    },
    timeChange(item) {
      this.interestArr.day = item;
      this.monthArr = [];
      this.weekArr = [];
      this.dayArr = [];
      this.dateArr = [];
      this.getByPreviousData();
      // this.findInterestByStrikesn();
    },
    //获取期权成交量（按天）全部数据
    getByPreviousData() {
      const params = {
        currency: this.radio,
        queryType: "byPrevious",
        timeType: this.interestArr.day,
      };
      this.axios
        .get("https://trade.rta-finance.com/trade/deribitoption/trades/", {
          params,
        })
        .then((res) => {
          if (res.data.code == "0") {
            this.isRepetition = false;
            let tempData = res.data.data;

            const monthArr = [];
            const yearArr = [];
            const dayArr = [];
            const weekArr = [];
            tempData.forEach((item) => {
              const ovs = {
                amount_count: item.amount_count,
                day: item.day,
              };
              if (item.option_trade_type == "month") {
                monthArr.push(ovs);
              } else if (item.option_trade_type == "year") {
                yearArr.push(ovs);
              } else if (item.option_trade_type == "day") {
                dayArr.push(ovs);
              } else if (item.option_trade_type == "week") {
                weekArr.push(ovs);
              }
            });
            this.monthArr = monthArr.map((v) => v.amount_count.toFixed(2));
            this.weekArr = weekArr.map((v) => v.amount_count.toFixed(2));
            this.dayArr = dayArr.map((v) => v.amount_count.toFixed(2));
            this.dateArr = monthArr.map((v) =>
              this.formatDate(Date.parse(v.day), "yyyy-MM-dd")
            );

            this.findInterestByStrikesn();
          } else {
            this.monthArr = [];
            this.weekArr = [];
            this.dayArr = [];
            this.dateArr = [];
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.loading = false; // 确保在数据加载完成后隐藏加载动画
        });
    },
    findInterestByStrikesn() {
      const xData = this.dateArr;
      const ayData = this.monthArr;
      const byData = this.weekArr;
      const cyData = this.dayArr;
      // console.log(xData);
      if (xData.length == 0) {
        //暂无数据
        this.strikesOption = NOT_DATA_OPTION;
      } else {
        this.strikesOption = {
          backgroundColor: "#3d3f47",
          tooltip: {
            trigger: "axis",
            borderWidth: "0", //边框宽度设置1
            borderColor: "none", //设置边框颜色
            padding: 16,
            textStyle: {
              fontSize: 12,
              color: "rgba(209, 212, 220, 1)", //设置文字颜色
            },
            tooltip: {
              show: true,
            },
            extraCssText: "background:rgba(61, 63, 71, 1);",
            formatter: (params) => {
              let weekDays = this.weekDay(params[0].name);
              let result = `<div style="border-bottom: 1px solid rgba(151, 151, 151, 0.2); padding-bottom: 8px; margin-bottom: 8px">
                   ${weekDays}, ${params[0].name}
                  </div>`;
              params.reverse();
              params.forEach((item) => {
                var re = item.seriesName;
                var colors = "";
                switch (re) {
                  case "Monthly Options":
                    colors = "#4AB5E8";
                    break;
                  case "Weekly Options":
                    colors = "#EACCAE";
                    break;
                  case "Daily Options":
                    colors = "#B7EAE2";
                    break;
                }
                var aaa = this.formatNum(item.data);
                result += `<span style="color:${colors}">${item.seriesName} : ${aaa}</span><br/>  `;
              });
              return result;
            },
          },
          toolbox: {
            top: 20,
            top: 20,
            right: "2%",
            iconStyle: {
              borderColor: "#D1D4DC",
            },
            emphasis: {
              iconStyle: {
                borderColor: "#edbd88", // 鼠标悬停时的图标颜色
              },
            },
            feature: {
              saveAsImage: {
                title: "Download Chart",
                icon: "path://M746.666667 597.333333a149.333333 149.333333 0 0 0-79.36 23.04l-123.093334-123.093333 333.013334-332.8a21.333333 21.333333 0 0 0 0-30.293333 21.333333 21.333333 0 0 0-30.08 0L514.133333 467.2 181.12 134.186667a21.333333 21.333333 0 0 0-30.08 0 21.333333 21.333333 0 0 0 0 30.293333l333.013333 332.8-124.8 124.8A147.626667 147.626667 0 0 0 277.333333 597.333333a149.333333 149.333333 0 1 0 113.92 53.12l122.88-122.88 120.533334 120.533334A149.333333 149.333333 0 1 0 746.666667 597.333333zM277.333333 853.333333a106.666667 106.666667 0 1 1 106.666667-106.666666 106.666667 106.666667 0 0 1-106.666667 106.666666z m469.333334 0a106.666667 106.666667 0 1 1 106.666666-106.666666 106.666667 106.666667 0 0 1-106.666666 106.666666z",
              },
              restore: {
                show: true,
                title: "Restore",
              },
              myFull: myToolFn.myFull(() => this.$refs.chart.$el),
              myDownloadCSV: myToolFn.downloadCSV(() => [
                this.$refs.chart.option.xAxis,
                ...this.$refs.chart.option.series.map((v) => v.data),
              ]),
            },
          },
          grid: {
            left: "2%",
            right: "4%",
            bottom: "16%",
            containLabel: true,
          },
          legend: {
            top: 20,
            textStyle: {
              fontSize: 12,
              color: "#D1D4DC",
            },
            padding: [0, 32, 0, 0],
            itemHeight: 12,
            itemWidth: 12,
            itemGap: 40,
            data: [
              {
                name: "Monthly Options",
                icon: "roundRect",
              },
              {
                name: "Weekly Options",
                icon: "roundRect",
              },
              {
                name: "Daily Options",
                icon: "roundRect",
              },
            ],
          },
          dataZoom: [
            {
              type: "inside",
              start: 0,
              end: 100,
            },
            {
              start: 0,
              end: 100,
            },
          ],
          xAxis: [
            {
              type: "category",
              data: xData,
              axisPointer: {
                type: "shadow",
              },
              axisLabel: {
                showMaxLabel: true,
                // rotate: 60,
                textStyle: {
                  color: "rgba(209, 212, 220, 1)",
                },
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              axisLabel: {
                formatter: function (v) {
                  if (v > 1000 && v <= 1000000) {
                    return v / 1000 + "K";
                  } else if (v > 1000000 && v <= 1000000000) {
                    return v / 1000000 + "M";
                  } else if (v > 1000000000) {
                    return v / 1000000000 + "B";
                  } else {
                    return v;
                  }
                  // return v + 'K';
                },
                textStyle: {
                  color: "rgba(209, 212, 220, 1)",
                },
                margin: 20,
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                  color: "rgba(255, 255, 255, 0.1)",
                },
              },
            },
          ],
          series: [
            {
              name: "Daily Options",
              type: "bar",
              stack: "total",
              label: {
                show: false,
              },
              data: cyData,
              itemStyle: {
                color: "rgba(183, 234, 226, 1)",
              },
            },
            {
              name: "Weekly Options",
              type: "bar",
              stack: "total",
              label: {
                show: false,
              },
              data: byData,
              itemStyle: {
                color: "rgba(234, 204, 174, 1)",
              },
            },
            {
              name: "Monthly Options",
              type: "bar",
              stack: "total",
              data: ayData,
              // barWidth: 13,
              label: {
                show: false,
              },
              itemStyle: {
                color: "rgba(74, 181, 232, 1)",
              },
            },
          ],
          graphic: [
            {
              type: "image",
              id: "logo",
              right: "center",
              bottom: "center",
              z: 0,
              bounding: "all",
              style: {
                image: "echarts-bg_yYgTFo1.png", // 必须是https开头的图片路径地址
                width: 400,
                height: 68,
              },
            },
          ],
        };
      }
    },
    //（按到期日）全部数据
    getByExpirationData() {
      const params = {
        currency: this.radio,
        queryType: "byExpiration",
      };
      this.axios
        .get("https://trade.rta-finance.com/trade/deribitoption/trades/", {
          params,
        })
        .then((res) => {
          // console.log("按到期日", res.data);
          if (res.data.code == "0") {
            this.isRepetition = false;
            let tempData = res.data.data;
            const callArr = [];
            const putArr = [];
            tempData.forEach((item) => {
              const ovs = {
                expiration_timestamp: item.expiration_timestamp,
                amountCount: item.amountCount,
                amountValue: item.amountValue,
                day: item.expiration_timestamp,
              };
              if (item.option_type == "call") {
                callArr.push(ovs);
              } else if (item.option_type == "put") {
                putArr.push(ovs);
              }
            });
            this.callArr = callArr.map((v) => v.amountCount.toFixed(2));
            this.putArr = putArr.map((v) => v.amountCount.toFixed(2));
            this.callValues = callArr.map((v) => v.amountValue.toFixed(2));
            this.putValues = putArr.map((v) => v.amountValue.toFixed(2));
            this.byExpirationX = callArr.map((v) =>
              this.formatDate(Date.parse(v.day), "yyyy-MM-dd")
            );
            // c
            //   "x,y轴",
            //   this.callArr,
            //   this.putArr,
            //   this.callValues,
            //   this.putValues,
            //   this.byExpirationX
            // );
            this.findInterestByExpiration(this.callValues, this.putValues);
          } else {
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.loading1 = false; // 确保在数据加载完成后隐藏加载动画
        });
    },

    findInterestByExpiration() {
      const xData = this.byExpirationX;
      const ayData = this.callArr;
      const byData = this.putArr;

      if (xData.length == 0) {
        //暂无数据
        this.expirationOption = NOT_DATA_OPTION;
      } else {
        this.expirationOption = {
          backgroundColor: "#3d3f47",
          tooltip: {
            trigger: "item",
            borderWidth: "0", //边框宽度设置1
            borderColor: "none", //设置边框颜色
            padding: 20,

            textStyle: {
              fontSize: 12,
              color: "rgba(209, 212, 220, 1)", //设置文字颜色
            },

            formatter: (params) => {
              const dataIndex = params.dataIndex; // 获取鼠标移到的柱子的索引
              const seriesName = params.seriesName; // 获取柱子所属系列的名称
              const callValue = this.formatNum(this.callValues);
              const putValue = this.formatNum(this.putValues);
              const value =
                seriesName === "Calls"
                  ? this.formatNum(ayData[dataIndex])
                  : this.formatNum(byData[dataIndex]); // 获取柱子的值
              const notionalValue =
                seriesName === "Calls"
                  ? callValue[dataIndex]
                  : putValue[dataIndex]; // 获取柱子的值
              const result = `Expiration: ${this.formatNum(params.name)}<br>`;
              const notionalV = `Notional Value[USD]: $${this.formatNum(
                notionalValue
              )}<br>`;

              var colors = "";
              switch (seriesName) {
                case "Calls":
                  colors = "rgba(74, 181, 232, 1)";
                  break;
                case "Puts":
                  colors = "rgba(234, 204, 174, 1)";
                  break;
              }

              return `
                 ${result}<span style="color:${colors}">${seriesName} : ${value}</span><br>${notionalV}
              `;
            },
            extraCssText: "background:rgba(61, 63, 71, 1);",
          },
          toolbox: {
            top: 20,
            right: "2%",
            iconStyle: {
              borderColor: "#D1D4DC",
            },
            emphasis: {
              iconStyle: {
                borderColor: "#edbd88", // 鼠标悬停时的图标颜色
              },
            },
            feature: {
              saveAsImage: {
                title: "Download Chart",
                icon: "path://M746.666667 597.333333a149.333333 149.333333 0 0 0-79.36 23.04l-123.093334-123.093333 333.013334-332.8a21.333333 21.333333 0 0 0 0-30.293333 21.333333 21.333333 0 0 0-30.08 0L514.133333 467.2 181.12 134.186667a21.333333 21.333333 0 0 0-30.08 0 21.333333 21.333333 0 0 0 0 30.293333l333.013333 332.8-124.8 124.8A147.626667 147.626667 0 0 0 277.333333 597.333333a149.333333 149.333333 0 1 0 113.92 53.12l122.88-122.88 120.533334 120.533334A149.333333 149.333333 0 1 0 746.666667 597.333333zM277.333333 853.333333a106.666667 106.666667 0 1 1 106.666667-106.666666 106.666667 106.666667 0 0 1-106.666667 106.666666z m469.333334 0a106.666667 106.666667 0 1 1 106.666666-106.666666 106.666667 106.666667 0 0 1-106.666666 106.666666z",
              },
              restore: {
                show: true,
                title: "Restore",
              },
              myFull: myToolFn.myFull(() => this.$refs.chart1.$el),
              myDownloadCSV: myToolFn.downloadCSV(() => [
                this.$refs.chart1.option.xAxis,
                ...this.$refs.chart1.option.series.map((v) => v.data),
              ]),
            },
          },
          axisPointer: {
            type: "shadow", // Display tooltip only on the hovered bar
          },
          grid: {
            left: "2%",
            right: "4%",
            bottom: "4%",
            containLabel: true,
          },
          legend: {
            top: 20,
            textStyle: {
              fontSize: 12,
              color: "#D1D4DC",
            },
            padding: [0, 32, 0, 0],
            padding: [0, 32, 0, 0],
            itemHeight: 12,
            itemWidth: 12,
            itemGap: 40,
            data: [
              {
                name: "Calls",
                icon: "roundRect",
              },
              {
                name: "Puts",
                icon: "roundRect",
              },
            ],
          },
          xAxis: [
            {
              type: "category",
              data: xData,
              axisPointer: {
                type: "shadow",
              },
              axisLabel: {
                showMaxLabel: true,
                rotate: 60,
                textStyle: {
                  color: "rgba(209, 212, 220, 1)",
                },
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              axisLabel: {
                formatter: function (v) {
                  if (v > 1000 && v <= 1000000) {
                    return v / 1000 + "K";
                  } else if (v > 1000000 && v <= 1000000000) {
                    return v / 1000000 + "M";
                  } else if (v > 1000000000) {
                    return v / 1000000000 + "B";
                  } else {
                    return v;
                  }
                  // return v + 'K';
                },
                textStyle: {
                  color: "rgba(209, 212, 220, 1)",
                },
                margin: 20,
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                  color: "rgba(255, 255, 255, 0.1)",
                },
              },
            },
          ],
          series: [
            {
              name: "Calls",
              type: "bar",
              data: ayData,
              itemStyle: {
                color: "rgba(74, 181, 232, 1)",
              },
              emphasis: {
                focus: "series",
              },
            },
            {
              name: "Puts",
              type: "bar",
              data: byData,
              itemStyle: {
                color: "rgba(234, 204, 174, 1)",
              },
              emphasis: {
                focus: "series",
              },
            },
          ],
          graphic: [
            {
              type: "image",
              id: "logo",
              right: "center",
              bottom: "center",
              z: 0,
              bounding: "all",
              style: {
                image: "echarts-bg_yYgTFo1.png", // 必须是https开头的图片路径地址
                width: 400,
                height: 68,
              },
            },
          ],
        };
      }
    },
    maxDateChange(item) {
      this.PainPoints.data = item;
      this.instrumentArr = [];
      this.amountCountArr = [];
      this.amountValueArr = [];
      this.getByInstrumentData();
    },

    //（最大成交量）全部数据
    getByInstrumentData() {
      const params = {
        currency: this.radio,
        queryType: "byInstrument",
        timeType: this.PainPoints.data,
      };
      this.axios
        .get("https://trade.rta-finance.com/trade/deribitoption/trades/", {
          params,
        })
        .then((res) => {
          if (res.data.code == "0") {
            this.isRepetition = false;
            let tempData = res.data.data;
            // console.log("最大成交量", tempData);
            this.instrumentArr = tempData.map((v) => v.instrument_name);
            this.amountCountArr = tempData.map((v) => v.amountCount.toFixed(2));
            this.amountValueArr = tempData.map((v) => v.amountValue.toFixed(2));
            this.findCurrentDay();
            // console.log(
            //   "最大成交量",
            //   this.instrumentArr,
            //   this.amountCountArr,
            //   this.amountValueArr
            // );
          } else {
            this.instrumentArr = [];
            this.amountCountArr = [];
            this.amountValueArr = [];
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.loading2 = false; // 确保在数据加载完成后隐藏加载动画
        });
    },
    findCurrentDay() {
      // let queryType = this.earningValue;
      const xData = this.instrumentArr;
      const ayData = this.amountCountArr;
      const notionalValue = this.amountValueArr;

      if (xData.length == 0) {
        //暂无数据
        this.currentDayOption = NOT_DATA_OPTION;
      } else {
        this.currentDayOption = {
          backgroundColor: "#3d3f47",
          tooltip: {
            trigger: "axis",
            borderWidth: "0", //边框宽度设置1
            borderColor: "none", //设置边框颜色
            formatter: (params) => {
              let result = "";
              params.forEach((item) => {
                var aaa = this.formatNum(item.value);

                const dataIndex = item.dataIndex;
                const notionalValue = this.amountValueArr;
                const notionalV = notionalValue[dataIndex];
                const noV = `Notional Value[USD]: $${this.formatNum(
                  notionalV
                )}<br>`;
                result = `${item.seriesName} :${aaa}</br>${noV}`;
              });
              return result;
            },
            padding: 20,
            textStyle: {
              fontSize: 12,
              color: "rgba(209, 212, 220, 1)", //设置文字颜色
            },
            tooltip: {
              show: true,
            },
            extraCssText: "background:rgba(61, 63, 71, 1);",
          },
          toolbox: {
            top: 20,
            right: "2%",
            iconStyle: {
              borderColor: "#D1D4DC",
            },
            emphasis: {
              iconStyle: {
                borderColor: "#edbd88", // 鼠标悬停时的图标颜色
              },
            },
            feature: {
              saveAsImage: {
                title: "Download Chart",
                icon: "path://M746.666667 597.333333a149.333333 149.333333 0 0 0-79.36 23.04l-123.093334-123.093333 333.013334-332.8a21.333333 21.333333 0 0 0 0-30.293333 21.333333 21.333333 0 0 0-30.08 0L514.133333 467.2 181.12 134.186667a21.333333 21.333333 0 0 0-30.08 0 21.333333 21.333333 0 0 0 0 30.293333l333.013333 332.8-124.8 124.8A147.626667 147.626667 0 0 0 277.333333 597.333333a149.333333 149.333333 0 1 0 113.92 53.12l122.88-122.88 120.533334 120.533334A149.333333 149.333333 0 1 0 746.666667 597.333333zM277.333333 853.333333a106.666667 106.666667 0 1 1 106.666667-106.666666 106.666667 106.666667 0 0 1-106.666667 106.666666z m469.333334 0a106.666667 106.666667 0 1 1 106.666666-106.666666 106.666667 106.666667 0 0 1-106.666666 106.666666z",
              },
              restore: {
                show: true,
                title: "Restore",
              },
              myFull: myToolFn.myFull(() => this.$refs.chart2.$el),
              myDownloadCSV: myToolFn.downloadCSV(() => [
                this.$refs.chart2.option.xAxis,
                ...this.$refs.chart2.option.series.map((v) => v.data),
              ]),
            },
          },
          grid: {
            left: "2%",
            right: "4%",
            bottom: "8%",
            top: 50,
            containLabel: true,
          },
          xAxis: [
            {
              type: "category",
              data: xData,
              axisPointer: {
                type: "shadow",
              },
              axisLabel: {
                textStyle: {
                  color: "rgba(209, 212, 220, 1)",
                },
                interval: 0,
                //文本格式化和布局
                formatter: function (value) {
                  // 找到第一个"-"的位置
                  let firstDashIndex = value.indexOf("-");
                  // 找到第二个"-"的位置
                  let secondDashIndex = value.indexOf("-", firstDashIndex + 1);

                  // 第一行：从开头到第一个"-"
                  let firstLine = "{a|" + value.slice(0, firstDashIndex) + "}";
                  // 第二行：从第一个"-"到第二个"-"
                  let secondLine =
                    "{b|" +
                    value.slice(firstDashIndex + 1, secondDashIndex + 1) +
                    "}";
                  // 第三行：从第二个"-"直到最后
                  let thirdLine =
                    "{c|" + value.slice(secondDashIndex + 1) + "}";

                  // 如果value长度大于7，则进行换行拼接，否则只返回第一行
                  if (value.length > 7) {
                    return firstLine + "\n" + secondLine + "\n" + thirdLine;
                  } else {
                    return firstLine;
                  }
                },

                rich: {
                  a: {
                    color: "rgba(209, 212, 220, 1)",
                    lineHeight: 16, // 控制每行的行高
                  },
                  b: {
                    color: "rgba(209, 212, 220, 1)",
                    lineHeight: 16,
                  },
                  c: {
                    color: "rgba(209, 212, 220, 1)",
                    lineHeight: 16,
                  },
                },
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              axisLabel: {
                formatter: function (v) {
                  if (v > 1000 && v <= 1000000) {
                    return v / 1000 + "K";
                  } else if (v > 1000000 && v <= 1000000000) {
                    return v / 1000000 + "M";
                  } else if (v > 1000000000) {
                    return v / 1000000000 + "B";
                  } else {
                    return v;
                  }
                  // return v + 'K';
                },
                textStyle: {
                  color: "rgba(209, 212, 220, 1)",
                },
                margin: 20,
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                  color: "rgba(159, 159, 159, 0.1)",
                },
              },
            },
          ],
          series: [
            {
              name: "Amount",
              type: "bar",
              data: ayData,
              itemStyle: {
                color: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0.1,
                      color: "rgba(237, 189, 136, 1)", // 0% 处的颜色
                    },
                    {
                      offset: 0.9,
                      color: "rgba(247, 224, 189, 0)", // 100% 处的颜色
                    },
                  ],
                  globalCoord: false, // 缺省为 false
                },
              },
            },
          ],
          graphic: [
            {
              type: "image",
              id: "logo",
              right: "center",
              bottom: "center",
              z: 0,
              bounding: "all",
              style: {
                image: "echarts-bg_yYgTFo1.png", // 必须是https开头的图片路径地址
                width: 400,
                height: 68,
              },
            },
          ],
        };
      }
    },
    klineChange(item) {
      this.PainPoints.timeType = item;
      this.buyK = [];
      this.sellK = [];
      this.dateK = [];
      this.totalK = [];
      this.kk = [];
      this.getKlinData();
    },
    //获取比特币期权成交量所有数据
    getKlinData() {
      const params = {
        currency: this.radio,
        timeType: this.PainPoints.timeType,
      };
      this.axios
        .get("https://trade.rta-finance.com/trade/deribitoption/kline/", {
          params,
        })
        .then((res) => {
          if (res.data.code == "0") {
            this.isRepetition = false;
            let tempData = res.data.data;

            this.buyK = tempData.map((v) => v.buy_amount);
            this.sellK = tempData.map((v) => v.sell_amount);
            tempData.forEach((item) => {
              const ovs = item.buy_amount + item.sell_amount;

              this.totalK.push(ovs);
            });
            // console.log("比特币期权成交量所有数据", this.totalK);
            this.dateK = tempData.map(
              (v) => this.$formatDateTime(v.time)

              // this.dateTypeFormat("YYYY-mm-dd HH:MM", new Date(v.time))
            );

            tempData.forEach((item) => {
              const ovs = [
                item.open.toFixed(2),
                item.close.toFixed(2),
                item.low.toFixed(2),
                item.high.toFixed(2),
              ];
              this.kk.push(ovs);
            });
            this.findBtcOption();
            // console.log(this.buyK, this.sellK, this.dateK, this.kk);
          } else {
            this.buyK = [];
            this.sellK = [];
            this.dateK = [];
            this.totalK = [];
            this.kk = [];
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.loading3 = false; // 确保在数据加载完成后隐藏加载动画
        });
    },
    findBtcOption() {
      let radio = this.radio + "价格";
      // let queryType = this.earningValue;
      const xData = this.dateK;
      const ayData = this.totalK;
      const byData = this.buyK;
      const cyData = this.sellK;
      //（//开盘(open)，收盘(close)，最低(lowest)，最高(highest)）
      const kLineData = this.kk;
      let n = xData.length;

      if (xData.length == 0) {
        //暂无数据
        this.btcOption = NOT_DATA_OPTION;
      } else {
        this.btcOption = {
          backgroundColor: "#33353e",
          tooltip: {
            trigger: "axis",
            borderWidth: "0", //边框宽度设置1
            borderColor: "none", //设置边框颜色
            padding: 20,
            textStyle: {
              fontSize: 12,
              color: "rgba(209, 212, 220, 1)", //设置文字颜色
            },
            tooltip: {
              show: true,
            },
            extraCssText: "background:rgba(61, 63, 71, 1);",
            formatter: (params, i) => {
              let result = `<div style="border-bottom: 1px solid rgba(151, 151, 151, 0.2); padding-bottom: 8px; margin-bottom: 8px">
                    ${params[0].name}
                  </div>`;
              let shouldIgnore = false; // 标志变量，初始为false
              params.forEach((item, index) => {
                if (index === params.length - 1) {
                  shouldIgnore = true; // 当遍历到最后一个元素时，将标志变量设置为true
                  return;
                }
                var re = item.seriesName;
                var colors = "";
                switch (re) {
                  case "Total":
                    colors = "#EACCAE";
                    break;
                  case "Buy":
                    colors = "#4AB5E8";
                    break;
                  case "Sell":
                    colors = "#B7EAE2";
                    break;
                }
                var aaa = this.kmb(item.data);
                result += `<span style="color:${colors}">${item.seriesName} : ${aaa}</span><br/>`;
              });
              return (
                result +
                `<div>
                    ${params[3].seriesName}: <br/>
                    开盘：${params[3].data[1]} <br/>
                    收盘：${params[3].data[2]} <br/>
                    最低：${params[3].data[3]} <br/>
                    最高：${params[3].data[4]}
                  </div>  `
              );

              // return `
              //   <div>
              //     <div style="border-bottom: 1px solid rgba(151, 151, 151, 0.2); padding-bottom: 8px; margin-bottom: 8px">
              //       ${params[0].name}
              //     </div>
              //     <div><span style="color:#EACCAE">${params[0].seriesName}: ${aaa}</span><br/>
              //      <span style="color:#4AB5E8">${params[1].seriesName}:${bbb}</span><br/>
              //     <span style="color:#B7EAE2">${params[2].seriesName}: ${ccc}</span><br/></div>
              //     <div>
              //       ${params[3].seriesName}: <br/>
              //       开盘：${currentItemData[1]} <br/>
              //       收盘：${currentItemData[2]} <br/>
              //       最低：${currentItemData[3]} <br/>
              //       最高：${currentItemData[4]}
              //     </div>
              //   </div>
              // `;
            },

            axisPointer: {
              type: "line",
            },
          },
          dataZoom: [
            {
              type: "inside",
              startValue: n - 100,
              endValue: n,
            },
            {
              startValue: 0,
              endValue: 100,
            },
          ],
          toolbox: {
            top: 20,
            right: "2%",
            iconStyle: {
              borderColor: "#D1D4DC",
            },
            emphasis: {
              iconStyle: {
                borderColor: "#edbd88", // 鼠标悬停时的图标颜色
              },
            },
            feature: {
              saveAsImage: {
                title: "Download Chart",
                icon: "path://M746.666667 597.333333a149.333333 149.333333 0 0 0-79.36 23.04l-123.093334-123.093333 333.013334-332.8a21.333333 21.333333 0 0 0 0-30.293333 21.333333 21.333333 0 0 0-30.08 0L514.133333 467.2 181.12 134.186667a21.333333 21.333333 0 0 0-30.08 0 21.333333 21.333333 0 0 0 0 30.293333l333.013333 332.8-124.8 124.8A147.626667 147.626667 0 0 0 277.333333 597.333333a149.333333 149.333333 0 1 0 113.92 53.12l122.88-122.88 120.533334 120.533334A149.333333 149.333333 0 1 0 746.666667 597.333333zM277.333333 853.333333a106.666667 106.666667 0 1 1 106.666667-106.666666 106.666667 106.666667 0 0 1-106.666667 106.666666z m469.333334 0a106.666667 106.666667 0 1 1 106.666666-106.666666 106.666667 106.666667 0 0 1-106.666666 106.666666z",
              },
              restore: {
                show: true,
                title: "Restore",
              },
              myFull: myToolFn.myFull(() => this.$refs.chart3.$el),
              myDownloadCSV: myToolFn.downloadCSV(() => [
                this.$refs.chart3.option.xAxis,
                ...this.$refs.chart3.option.series.map((v) => v.data),
              ]),
            },
          },
          grid: {
            top: 80,
            left: "2%",
            right: 20,
            bottom: "14%",
            containLabel: true,
          },
          legend: {
            top: 20,
            textStyle: {
              fontSize: 12,
              color: "#D1D4DC",
            },
            padding: [0, 32, 0, 0],
            itemHeight: 12,
            itemWidth: 12,
            itemGap: 40,
            data: [
              {
                name: "Total",
                icon: "roundRect",
              },
              {
                name: "Buy",
                icon: "roundRect",
              },
              {
                name: "Sell",
                icon: "roundRect",
              },
              {
                name: radio,
                icon: "path://M225.28 303.476364 184.32 303.476364 184.32 613.282909 225.28 613.282909 225.28 720.523636 245.76 720.523636 245.76 613.282909 286.72 613.282909 286.72 303.476364 245.76 303.476364 245.76 184.32 225.28 184.32 225.28 303.476364 225.28 303.476364 225.28 303.476364 225.28 303.476364ZM409.6 506.042182 368.64 506.042182 368.64 753.887418 409.6 753.887418 409.6 839.68 430.08 839.68 430.08 753.887418 471.04 753.887418 471.04 506.042182 430.08 506.042182 430.08 410.717091 409.6 410.717091 409.6 506.042182 409.6 506.042182 409.6 506.042182ZM378.88 517.12 460.8 517.12 460.8 742.4 378.88 742.4 378.88 517.12 378.88 517.12 378.88 517.12ZM778.24 424.391111 737.28 424.391111 737.28 595.968 778.24 595.968 778.24 655.36 798.72 655.36 798.72 595.968 839.68 595.968 839.68 424.391111 798.72 424.391111 798.72 358.4 778.24 358.4 778.24 424.391111 778.24 424.391111 778.24 424.391111ZM747.52 432.06 829.44 432.06 829.44 588.015555 747.52 588.015555 747.52 432.06 747.52 432.06 747.52 432.06ZM593.92 266.405495 552.96 266.405495 552.96 479.827782 593.92 479.827782 593.92 553.704727 614.4 553.704727 614.4 479.827782 655.36 479.827782 655.36 266.405495 614.4 266.405495 614.4 184.32 593.92 184.32 593.92 266.405495 593.92 266.405495 593.92 266.405495Z",
              },
            ],
          },
          xAxis: [
            {
              type: "category",
              data: xData,
              axisPointer: {
                type: "shadow",
              },
              axisLabel: {
                showMaxLabel: true,
                textStyle: {
                  color: "#D1D4DC",
                },
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              name: "Option Trade Volume",
              position: "left",
              max: (value) => value.max * 2,
              // scale: true,
              axisLabel: {
                formatter: function (v) {
                  if (v > 1000 && v <= 1000000) {
                    return (v / 1000).toFixed(0) + "K";
                  } else if (v > 1000000 && v <= 1000000000) {
                    return (v / 1000000).toFixed(0) + "M";
                  } else if (v > 1000000000) {
                    return (v / 1000000000).toFixed(0) + "B";
                  } else {
                    return v.toFixed(0);
                  }
                  // return v + 'K';
                },
                textStyle: {
                  color: "#D1D4DC",
                },
                margin: 20,
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                  color: "rgba(255, 255, 255, 0.2)",
                },
              },
            },
            {
              type: "value",
              name: radio,
              position: "right",
              min: function (value) {
                return value.max / 6;
              },
              scale: true,
              axisLabel: {
                textStyle: {
                  color: "#D1D4DC",
                },
                margin: 20,
                formatter: function (v) {
                  return v.toFixed(0);
                },
              },
              splitLine: {
                show: false,
              },
            },
          ],
          series: [
            {
              name: "Total",
              type: "bar",
              data: ayData,
              yAxisIndex: 0,
              itemStyle: {
                color: "rgba(234, 204, 174,0.6)",
              },
            },
            {
              name: "Buy",
              type: "bar",
              data: byData,
              yAxisIndex: 0,
              itemStyle: {
                color: "rgba(74, 181, 232, 0.6)",
              },
            },
            {
              name: "Sell",
              type: "bar",
              data: cyData,
              yAxisIndex: 0,
              itemStyle: {
                color: "rgba(183, 234, 226, 0.6)",
              },
            },
            {
              name: radio,
              type: "candlestick",
              yAxisIndex: 1,
              data: kLineData,
              barMaxWidth: 40,
              itemStyle: {
                borderWidth: 2, // 这里的宽度可以根据需要进行调整
                color: "rgba(100, 176, 143, 1)", // 涨的颜色设为绿色
                color0: "rgba(219, 100, 110, 1)", // 跌的颜色设为红色
                borderColor: "rgba(100, 176, 143, 1)", // 涨的边框颜色
                borderColor0: "rgba(219, 100, 110, 1)", // 跌的边框颜色
              },
            },
          ],
          graphic: [
            {
              type: "image",
              id: "logo",
              right: "center",
              bottom: "center",
              z: 0,
              bounding: "all",
              style: {
                image: "echarts-bg_yYgTFo1.png", // 必须是https开头的图片路径地址
                width: 400,
                height: 68,
              },
            },
          ],
        };
      }
    },
    TradesChange(item) {
      this.PainPoints.blockTime = item;
      this.getBlockTrades();
    },
    //常规交易vs大宗交易全部数据
    getBlockTrades() {
      const params = {
        currency: this.radio,
        timeType: this.PainPoints.blockTime,
      };
      this.axios
        .get("https://trade.rta-finance.com/trade/deribitoption/info/", {
          params,
        })
        .then((res) => {
          if (res.data.code == "0") {
            this.isRepetition = false;
            let tempData = res.data.data;

            this.blockTrade =
              res.data.data.blockTrade == null
                ? "0"
                : res.data.data.blockTrade.toFixed(2);
            this.regularTrade =
              res.data.data.regularTrade == null
                ? "0"
                : res.data.data.regularTrade.toFixed(2);
            this.findRegularTrades();
            // console.log(
            //   "常规交易vs大宗交易",
            //   this.blockTrade,
            //   this.regularTrade
            // );
            //this.buyK = tempData.map((v) => v.buy_amount);
          } else {
            this.blockTrade = [];
            this.regularTrade = [];
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.loading4 = false; // 确保在数据加载完成后隐藏加载动画
        });
    },
    findRegularTrades() {
      this.tradeOption = {
        backgroundColor: "#3d3f47",
        tooltip: {
          borderWidth: "0", //边框宽度设置1
          borderColor: "none", //设置边框颜色
          textStyle: {
            fontSize: 12,
            color: "rgba(209, 212, 220, 1)", //设置文字颜色
          },
          tooltip: {
            show: true,
          },
          extraCssText: "background:rgba(61, 63, 71, 1);",
          trigger: "item",
          // formatter: '{a}</br>{b}:{c}' // d为百分比
          formatter: (params) => {
            var aaa = this.formatNum(params.data.value);
            return `${params.data.name} : ${aaa}`;
          },
        },
        toolbox: {
          top: 10,
          right: "2%",
          iconStyle: {
            borderColor: "#D1D4DC",
          },
          emphasis: {
            iconStyle: {
              borderColor: "#edbd88", // 鼠标悬停时的图标颜色
            },
          },
          feature: {
            saveAsImage: {
              title: "Download Chart",
              icon: "path://M746.666667 597.333333a149.333333 149.333333 0 0 0-79.36 23.04l-123.093334-123.093333 333.013334-332.8a21.333333 21.333333 0 0 0 0-30.293333 21.333333 21.333333 0 0 0-30.08 0L514.133333 467.2 181.12 134.186667a21.333333 21.333333 0 0 0-30.08 0 21.333333 21.333333 0 0 0 0 30.293333l333.013333 332.8-124.8 124.8A147.626667 147.626667 0 0 0 277.333333 597.333333a149.333333 149.333333 0 1 0 113.92 53.12l122.88-122.88 120.533334 120.533334A149.333333 149.333333 0 1 0 746.666667 597.333333zM277.333333 853.333333a106.666667 106.666667 0 1 1 106.666667-106.666666 106.666667 106.666667 0 0 1-106.666667 106.666666z m469.333334 0a106.666667 106.666667 0 1 1 106.666666-106.666666 106.666667 106.666667 0 0 1-106.666666 106.666666z",
            },
            restore: {
              show: true,
              title: "Restore",
            },
            myFull: myToolFn.myFull(() => this.$refs.chart4.$el),
            myDownloadCSV: myToolFn.downloadCSV(
              () =>
                ["name", "value"].map((v) =>
                  this.$refs.chart4.option.series[0].data.map(
                    (value) => value[v]
                  )
                ),
              false
            ),
          },
        },
        series: [
          {
            name: "Regular Trades",
            type: "pie",
            radius: "55%",
            center: ["50%", "45%"],

            data: [
              {
                value: this.regularTrade,
                name: "Regular Trades",
                itemStyle: { color: "rgba(22, 29, 48, 1)" },
              },
              {
                value: this.blockTrade,
                name: "Block Trades",
                itemStyle: { color: "rgba(239, 195, 148, 1)" },
                // itemStyle: { color: "rgba(178, 145, 112, 1)" },
              },
              // {
              //   value: 20,
              //   name: "Block Combo Trades",
              //   itemStyle: { color: "rgba(239, 195, 148, 1)" },
              // },
            ].sort(function (a, b) {
              return a.value - b.value;
            }),
            // roseType: "radius",
            label: {
              color: "#D1D4DC",
              show: true,
              position: "outer",
              formatter: function (params) {
                return `{a|${params.name}}\n{b|${params.percent}%}`;
              },
              rich: {
                a: {
                  color: "#D1D4DC",
                  fontSize: 12, // 根据需要调整字体大小
                  lineHeight: 20, // 根据需要调整行高
                  align: "center",
                },
                b: {
                  color: "#D1D4DCF",
                  fontSize: 12, // 根据需要调整字体大小
                  lineHeight: 20, // 根据需要调整行高
                  align: "center",
                },
              },
            },
            animationType: "scale",
            animationEasing: "elasticOut",
            animationDelay: function (idx) {
              return Math.random() * 200;
            },
          },
        ],
        graphic: graphic("echarts-bg_yYgTFo1.png"),
      };
    },
    regularChange(item) {
      this.PainPoints.regularDate = item;
      this.getRegularData();
    },
    //常规交易
    getRegularData() {
      const params = {
        currency: this.radio,
        timeType: this.PainPoints.regularDate,
        queryType: "regular",
      };
      this.axios
        .get("https://trade.rta-finance.com/trade/deribitoption/info/", {
          params,
        })
        .then((res) => {
          if (res.data.code == "0") {
            this.isRepetition = false;

            this.regularBuyCall =
              res.data.data.buyCall == null
                ? "0"
                : res.data.data.buyCall.toFixed(2);

            this.regularBuyPut =
              res.data.data.buyPut == null
                ? "0"
                : res.data.data.buyPut.toFixed(2);

            this.regularSellCall =
              res.data.data.sellCall == null
                ? "0"
                : res.data.data.sellCall.toFixed(2);

            this.regularSellPut =
              res.data.data.sellPut == null
                ? "0"
                : res.data.data.sellPut.toFixed(2);
            this.findBlockTrade();
            // console.log(
            //   "常规交易",
            //   this.blockTrade,
            //   this.regularTrade
            // );
            //this.buyK = tempData.map((v) => v.buy_amount);
          } else {
            this.blockTrade = [];
            this.regularTrade = [];
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.loading5 = false; // 确保在数据加载完成后隐藏加载动画
        });
    },

    findBlockTrade() {
      this.blockTradeOption = {
        backgroundColor: "#3d3f47",
        tooltip: {
          borderWidth: "0", //边框宽度设置1
          borderColor: "none", //设置边框颜色
          textStyle: {
            fontSize: 12,
            color: "rgba(209, 212, 220, 1)", //设置文字颜色
          },

          tooltip: {
            show: true,
          },
          extraCssText: "background:rgba(61, 63, 71, 1);",
          trigger: "item",
          formatter: (params) => {
            var aaa = this.formatNum(params.data.value);
            return `${params.data.name} : ${aaa}`;
          },
        },
        toolbox: {
          top: 10,
          right: "2%",
          iconStyle: {
            borderColor: "#D1D4DC",
          },
          emphasis: {
            iconStyle: {
              borderColor: "#edbd88", // 鼠标悬停时的图标颜色
            },
          },
          feature: {
            saveAsImage: {
              title: "Download Chart",
              icon: "path://M746.666667 597.333333a149.333333 149.333333 0 0 0-79.36 23.04l-123.093334-123.093333 333.013334-332.8a21.333333 21.333333 0 0 0 0-30.293333 21.333333 21.333333 0 0 0-30.08 0L514.133333 467.2 181.12 134.186667a21.333333 21.333333 0 0 0-30.08 0 21.333333 21.333333 0 0 0 0 30.293333l333.013333 332.8-124.8 124.8A147.626667 147.626667 0 0 0 277.333333 597.333333a149.333333 149.333333 0 1 0 113.92 53.12l122.88-122.88 120.533334 120.533334A149.333333 149.333333 0 1 0 746.666667 597.333333zM277.333333 853.333333a106.666667 106.666667 0 1 1 106.666667-106.666666 106.666667 106.666667 0 0 1-106.666667 106.666666z m469.333334 0a106.666667 106.666667 0 1 1 106.666666-106.666666 106.666667 106.666667 0 0 1-106.666666 106.666666z",
            },
            restore: {
              show: true,
              title: "Restore",
            },
            myFull: myToolFn.myFull(() => this.$refs.chart5.$el),
            myDownloadCSV: myToolFn.downloadCSV(
              () =>
                ["name", "value"].map((v) =>
                  this.$refs.chart5.option.series[0].data.map(
                    (value) => value[v]
                  )
                ),
              false
            ),
          },
        },
        series: [
          {
            name: "Regular Trade Breakdown",
            type: "pie",
            radius: "55%",
            center: ["50%", "45%"],
            data: [
              {
                value: this.regularBuyCall,
                name: "Calls Bought",
                itemStyle: { color: "#4AB5E8" },
                z: 2,
              },
              {
                value: this.regularSellCall,
                name: "Calls Sold",
                itemStyle: { color: "#329FD4" },
                z: 2,
              },

              {
                value: this.regularSellPut,
                name: "Puts Sold",
                itemStyle: { color: "#D2AB84" },
                z: 2,
              },
              {
                value: this.regularBuyPut,
                name: "Puts Bought",
                itemStyle: { color: "#E1C2A4" },
                z: 2,
              },
            ],
            label: {
              color: "#D1D4DC",
              show: true,
              position: "outer",
              formatter: function (params) {
                return `{a|${params.name}}\n{b|${params.percent}%}`;
              },
              rich: {
                a: {
                  color: "#D1D4DC",
                  fontSize: 12, // 根据需要调整字体大小
                  lineHeight: 20, // 根据需要调整行高
                  align: "center",
                },
                b: {
                  color: "#D1D4DC",
                  fontSize: 12, // 根据需要调整字体大小
                  lineHeight: 20, // 根据需要调整行高
                  align: "center",
                },
              },
            },
            animationType: "scale",
            animationEasing: "elasticOut",
            animationDelay: function (idx) {
              return Math.random() * 200;
            },
          },
        ],
        graphic: graphic("echarts-bg_yYgTFo1.png"),
      };
    },
    brockChange(item) {
      this.PainPoints.blockDate = item;
      this.getBrockData();
    },
    //大宗交易
    getBrockData() {
      const params = {
        currency: this.radio,
        timeType: this.PainPoints.blockDate,
        queryType: "block",
      };
      this.axios
        .get("https://trade.rta-finance.com/trade/deribitoption/info/", {
          params,
        })
        .then((res) => {
          if (res.data.code == "0") {
            this.isRepetition = false;

            this.brockBuyCall =
              res.data.data.buyCall == null
                ? "0"
                : res.data.data.buyCall.toFixed(2);

            this.brockBuyPut =
              res.data.data.buyPut == null
                ? "0"
                : res.data.data.buyPut.toFixed(2);

            this.brockSellCall =
              res.data.data.sellCall == null
                ? "0"
                : res.data.data.sellCall.toFixed(2);

            this.brockSellPut =
              res.data.data.sellPut == null
                ? "0"
                : res.data.data.sellPut.toFixed(2);
            this.findTopVolumee();
          } else {
            this.blockTrade = [];
            this.regularTrade = [];
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.loading6 = false; // 确保在数据加载完成后隐藏加载动画
        });
    },
    findTopVolumee() {
      this.topVolumeOption = {
        backgroundColor: "#3d3f47",
        tooltip: {
          borderWidth: "0", //边框宽度设置1
          borderColor: "none", //设置边框颜色
          textStyle: {
            fontSize: 12,
            color: "rgba(209, 212, 220, 1)", //设置文字颜色
          },

          tooltip: {
            show: true,
          },
          extraCssText: "background:rgba(61, 63, 71, 1);",
          trigger: "item",
          formatter: (params) => {
            var aaa = this.formatNum(params.data.value);
            return `${params.data.name} : ${aaa}`;
          },
        },
        toolbox: {
          top: 10,
          right: "2%",
          iconStyle: {
            borderColor: "#D1D4DC",
          },
          emphasis: {
            iconStyle: {
              borderColor: "#edbd88", // 鼠标悬停时的图标颜色
            },
          },
          feature: {
            saveAsImage: {
              title: "Download Chart",
              icon: "path://M746.666667 597.333333a149.333333 149.333333 0 0 0-79.36 23.04l-123.093334-123.093333 333.013334-332.8a21.333333 21.333333 0 0 0 0-30.293333 21.333333 21.333333 0 0 0-30.08 0L514.133333 467.2 181.12 134.186667a21.333333 21.333333 0 0 0-30.08 0 21.333333 21.333333 0 0 0 0 30.293333l333.013333 332.8-124.8 124.8A147.626667 147.626667 0 0 0 277.333333 597.333333a149.333333 149.333333 0 1 0 113.92 53.12l122.88-122.88 120.533334 120.533334A149.333333 149.333333 0 1 0 746.666667 597.333333zM277.333333 853.333333a106.666667 106.666667 0 1 1 106.666667-106.666666 106.666667 106.666667 0 0 1-106.666667 106.666666z m469.333334 0a106.666667 106.666667 0 1 1 106.666666-106.666666 106.666667 106.666667 0 0 1-106.666666 106.666666z",
            },
            restore: {
              show: true,
              title: "Restore",
            },
            myFull: myToolFn.myFull(() => this.$refs.chart6.$el),
            myDownloadCSV: myToolFn.downloadCSV(
              () =>
                ["name", "value"].map((v) =>
                  this.$refs.chart6.option.series[0].data.map(
                    (value) => value[v]
                  )
                ),
              false
            ),
          },
        },
        series: [
          {
            name: "Block Trade Breakdown",
            type: "pie",
            radius: "55%",
            center: ["50%", "45%"],
            data: [
              {
                value: this.brockSellCall,
                name: "Calls Sold",
                itemStyle: { color: "#329FD4" },
              },
              {
                value: this.brockBuyCall,
                name: "Calls Bought",
                itemStyle: { color: "#4AB5E8" },
              },
              {
                value: this.brockSellPut,
                name: "Puts Sold",
                itemStyle: { color: "#D2AB84" },
              },
              {
                value: this.brockBuyPut,
                name: "Puts Bought",
                itemStyle: { color: "#E1C2A4" },
              },
            ],
            label: {
              color: "#D1D4DC",
              show: true,
              position: "outer",
              formatter: function (params) {
                return `{a|${params.name}}\n{b|${params.percent}%}`;
              },
              rich: {
                a: {
                  color: "#D1D4DC",
                  fontSize: 12, // 根据需要调整字体大小
                  lineHeight: 20, // 根据需要调整行高
                  align: "center",
                },
                b: {
                  color: "#D1D4DC",
                  fontSize: 12, // 根据需要调整字体大小
                  lineHeight: 20, // 根据需要调整行高
                  align: "center",
                },
              },
            },
            animationType: "scale",
            animationEasing: "elasticOut",
            animationDelay: function (idx) {
              return Math.random() * 200;
            },
          },
        ],
        graphic: graphic("echarts-bg_yYgTFo1.png"),
      };
    },
  },
};
</script>
<style lang="scss" >
.currency-btn {
  margin-bottom: 2rem;
  width: 100%;
  border-bottom: 1px solid rgba(239, 195, 148, 0.3);
  .el-radio-group {
    .el-radio-button {
      margin: 0 32px 0 0;
      .el-radio-button__inner {
        background: transparent !important;
        border: none;
        height: 44px;
        line-height: 44px;
        padding: 0 !important;
        font-size: 20px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #d1d4dc;
        border-radius: 0 !important;
      }
      .el-radio-button__inner:focus {
        border-bottom: 2px solid #edbd88;
        color: #edbd88;
      }
      .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        border-bottom: 2px solid #edbd88;
        color: #edbd88;
      }
    }
  }
}
@import "./scss/option.scss";
</style>