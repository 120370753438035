<template>
  <section>
    <b-card class="aaaa">
      <b-card-body class="tabletrade">
        <div class="deribit-select">
          <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <el-form-item label="期权方向">
              <el-select v-model="formInline.option_type" placeholder="All">
                <el-option label="All" value=""></el-option>
                <el-option label="Call" value="call"></el-option>
                <el-option label="Put" value="put"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="标的周期">
              <el-select
                v-model="formInline.settlement_period"
                placeholder="All"
              >
                <el-option label="All" value=""></el-option>
                <el-option label="Day" value="day"></el-option>
                <el-option label="Week" value="week"></el-option>
                <el-option label="Month" value="month"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="行权价">
              <el-select v-model="formInline.strikeValue" placeholder="All">
                <el-option label="All" value=""></el-option>
                <el-option
                  v-for="item in formInline.strike"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
                <!-- <el-option label="1" value=this.fom></el-option> -->
              </el-select>
            </el-form-item>
            <el-form-item label="行权日">
              <el-form-item prop="date1" class="exercise-day">
                <el-date-picker
                  type="date"
                  placeholder="选择日期"
                  v-model="formInline.expiration_timestamp"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  style="width: 120px"
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </el-form-item>
            </el-form-item>
            <!-- <el-form-item label="时间2">
                        <el-form-item prop="date2">
                          <el-date-picker
                            type="date"
                            placeholder="选择日期"
                            v-model="formInline.expiration_timestamp"
                            style="width: 140px"
                          ></el-date-picker>
                        </el-form-item>
                      </el-form-item> -->
            <el-form-item>
              <el-button type="primary" @click="primaryclick">查询</el-button>
            </el-form-item>
          </el-form>
        </div>

        <el-table
          v-if="tableData.length > 0"
          v-loading="isLoading"
          element-loading-background="rgba(0, 0, 0, 0.5)"
          element-loading-text="加载中，请稍后..."
          element-loading-spinner="el-icon-loading"
          :data="tableData"
          style="width: 100%; background-color: transparent; font-size: 14px"
          class="deribit-table"
          :row-style="{ height: '60px' }"
          :row-class-name="tableRowClassName"
        >
          <el-table-column prop="instrument_name" label="标的名" width="200">
            <template slot-scope="scope">
              <router-link
                tag="a"
                :to="{
                  path: '/inHistoricalData',
                  query: {
                    id: scope.row.instrument_name,
                    bc: 'BTC',
                  },
                }"
                >{{ scope.row.instrument_name }}</router-link
              >
            </template>
          </el-table-column>
          <el-table-column prop="base_currency" label="基础货币" width="80">
          </el-table-column>
          <el-table-column prop="option_type" label="期权方向" width="80">
          </el-table-column>
          <el-table-column prop="tick_size" label="最小升降单位">
            <template slot-scope="scope">{{
              parseFloat(scope.row.tick_size)
            }}</template>
          </el-table-column>
          <el-table-column prop="taker_commission" label="TAKER手续费">
            <template slot-scope="scope">{{
              parseFloat(scope.row.taker_commission)
            }}</template>
          </el-table-column>
          <el-table-column prop="strike" label="行权价" width="80">
          </el-table-column>
          <el-table-column prop="settlement_period" label="结算周期">
          </el-table-column>
          <el-table-column prop="min_trade_amount" label="最小交易量">
            <template slot-scope="scope">{{
              parseFloat(scope.row.min_trade_amount)
            }}</template>
          </el-table-column>
          <el-table-column prop="maker_commission" label="MAKER手续费">
            <template slot-scope="scope">{{
              parseFloat(scope.row.maker_commission)
            }}</template>
          </el-table-column>
          <el-table-column prop="kind" label="品种" width="80">
          </el-table-column>

          <el-table-column prop="expiration_timestamp" label="交割日期">
            <template slot-scope="scope">{{
              scope.row.expiration_timestamp | dateFormat
            }}</template>
          </el-table-column>
          <el-table-column prop="creation_timestamp" label="创建日期">
            <template slot-scope="scope">{{
              scope.row.creation_timestamp | dateFormat
            }}</template>
          </el-table-column>
          <el-table-column prop="contract_size" label="合约股数" width="80">
          </el-table-column>
          <el-table-column prop="block_trade_commission" label="大宗交易手续费">
          </el-table-column>
          <el-table-column prop="quote_currency" label="计价货币" width="80">
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageParams.currentPage"
            :page-sizes="[5, 10, 20, 50, 100]"
            :page-size="pageParams.pageSize"
            layout="sizes, prev, pager, next"
            :total="pageParams.total"
          >
          </el-pagination>
        </div>
      </b-card-body>
    </b-card>
  </section>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
} from "bootstrap-vue";
export default {
  components: { BCard, BCardBody, BCardHeader, BCardTitle, BRow, BCol },
  props: {
    radio: { type: String },
    name: { type: String },
    activeNameTab: {
      type: String,
      required: true,
    },
  }, //

  data() {
    return {
      bc: "",
      isRepetition: false, // 防止重复执行
      currentValue: "",
      activeName: "BTC",
      pageParams: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      isLoading: false,
      loading: false,
      tableData: [],
      tableData1: [],
      formInline: {
        option_type: "",
        settlement_period: "",
        strike: [],
        strikeValue: "",
        end_timestamp: "",
        expiration_timestamp: "",
        start_timestamp: "",
        creation_timestamp: "",
      },
      pickerOptions: {
        disabledDate(time) {
          // 禁止选择过去日期（可以选择今天）
          return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
        },
      },
      tempData: [], //存放DeribitBTC全部数据
      pageData: [],
      total: 0,
      tempEthData: [], //存放DeribitETC全部数据
      tradesData: [],
      newTime: null, // 新的时间
    };
  },
  created() {},
  watch: {
    activeNameTab(newValue) {
      this.getListDataPageAction(this.name, newValue, this.radio);
    },
    radio: {
      handler(newValue) {
        this.getListDataPageAction(this.name, this.activeNameTab, newValue);
      },
      immediate: true,
    },
    // radio(val) {
    //   //   // value变换赋值给currentValue
    //   this.currentValue = val;

    //   console.log(this.currentValue);
    // },
  },
  computed: {},
  mounted() {},
  methods: {
    //奇偶行背景色不同
    tableRowClassName({ rowIndex }) {
      if ((rowIndex + 1) % 2 === 0) {
        return "oddRow";
      }

      return "evenRow";
    },
    handleClick(tab, event) {
      //console.log(tab, event);
    },
    //判断一下只执行当前选中的标签页
    getListDataPageAction(name, activeNameTab, radio) {
      if (name === activeNameTab && !this.isRepetition) {
        this.isRepetition = true;
        this.getListData(radio);
      }
    },
    // 获取期权bybit BTC期权标的所以数据
    getListData(radio) {
      //请求之前，开启loading
      this.isLoading = true;
      let params = {
        bc: radio,
        p: this.pageParams.currentPage,
        l: this.pageParams.pageSize,
        // s: "-1",
        ot: this.formInline.option_type,
        et: this.formInline.end_timestamp,
        sp: this.formInline.settlement_period,
        strike: this.formInline.strikeValue,
      };
      this.axios
        .get("https://api.rta.trade/api/v1/deribitOptionInstrument", {
          params,
        })
        .then((res) => {
          if (res.data.code == "0") {
            this.isRepetition = false;
            // console.log(res);
            let tempData = res.data.data;
            // 表格数据
            this.tableData = tempData;
            // strike数组
            this.formInline.strike = res.data.strike;

            let pageData = res.data.meta;
            // console.log("-->", pageData)
            this.pageParams.total = res.data.meta.total;
            // console.log("<----", this.total)
            //请求成功，关闭loading
            this.isLoading = false;
          }
        })
        .catch((err) => {
          this.tableData = "";
          this.pageParams.total = 1;
          this.pageParams.pageSize = 10;
        });
    },
    handleSizeChange(val) {
      this.getListData();
      // console.log("activeNAME-->", this.activeName);
      this.pageParams.pageSize = val;
      // if (this.activeName === "BTC") {
      //   this.getListData();
      // } else {
      //   this.getEthListData();
      // }

      // console.log("pageSize-->", this.pageParams.pageSize);
    },

    handleCurrentChange(val) {
      this.getListData();
      // console.log("activeNAME-->", this.activeName);
      // if (this.activeName === "BTC") {
      //   this.getListData();
      // } else {
      //   this.getEthListData();
      // }
      this.pageParams.currentPage = val;
      // console.log("currentPage-->", this.pageParams.currentPage);
    }, //搜索条件筛选
    primaryclick() {
      //ct转成时间戳
      // if (
      //   this.formInline.creation_timestamp != null &&
      //   this.formInline.creation_timestamp != ""
      // ) {
      //   this.formInline.start_timestamp = `${
      //     new Date(this.formInline.creation_timestamp).getTime() / 1000
      //   }`;
      // } else {
      //   this.formInline.start_timestamp = "";
      // }
      if (
        this.formInline.expiration_timestamp != null &&
        this.formInline.expiration_timestamp != ""
      ) {
        let expiration_timestamp = new Date(
          this.formInline.expiration_timestamp
        );
        expiration_timestamp = expiration_timestamp.setHours(
          expiration_timestamp.getHours() + 8
        );

        this.formInline.end_timestamp = `${
          new Date(new Date(expiration_timestamp)).getTime() / 1000
        }`;
        // console.log("------->", this.formInline.end_timestamp);
      } else {
        this.formInline.end_timestamp = "";
      }
      this.getListData();
      // console.log("11111",this.getListData)
    },
  },
  filters: {
    dateFormat(dateStr) {
      let fmt = "yyyy-MM-dd";
      let date = new Date(dateStr);
      var o = {
        "M+": date.getMonth() + 1, //月份
        "d+": date.getDate(), //日
        "h+": date.getHours(), //小时
        "m+": date.getMinutes(), //分
        "s+": date.getSeconds(), //秒
        "q+": Math.floor((date.getMonth() + 3) / 3), //季度
        S: date.getMilliseconds(), //毫秒
      };
      if (/(y+)/.test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          (date.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
      return fmt;
    },
  },
};
</script>
<style lang="scss">
.Deribit-tabs {
  .el-tabs__nav-wrap::after {
    height: 0;
  }
  .el-tabs__active-bar {
    background: transparent;
  }
  .el-tabs__active-bar {
    background-color: transparent !important;
  }
  .el-tabs__header {
    padding: 0;
    margin: 26px 0 40px;
  }
}
.el-tabs__item {
  font-size: 14px;
  color: #ffff;
  width: 80px;
  height: 40px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 0;
  margin-right: 20px;
  text-align: center;
}
// .el-tabs__active-bar {
//   background-color: #efc394 !important; //Tabs 标签页切换下划线颜色
// }
.el-tabs__item:hover {
  color: #000000;
  background: #efc394;
}
.el-tabs__item.is-active {
  color: #000000;
  background: #efc394;
}
.fundsRate {
  color: #ffff !important;
}
.select-container {
  display: flex;
  .chart-select {
    width: 130px;
    margin: 0 10px;
    .el-input--prefix .el-input__inner {
      color: #ffff !important;
      background: rgb(40, 48, 70);
    }
    .el-input__inner:focus {
      border-color: #efc394 !important;
    }
  }
}
.card-body {
  padding: 20px 0 !important;
}
.tabletrade {
  padding: 0 !important;
  .deribit-select {
    width: 100%;
    margin-bottom: 24px;
    // border: 1px solid rgba(247, 235, 235, 0.2);
    // border-bottom: 1px solid rgba(247, 235, 235, 0.2) !important;
    .el-input--prefix .el-input__inner {
      border: none;
      color: #ffff !important;
      background: rgb(40, 48, 70);
      cursor: pointer;
    }
    .el-input__inner:focus {
      border-color: #efc394 !important;
    }
    .el-form--inline {
      display: flex;
      justify-content: space-around;
      justify-content: flex-end;
      align-items: center;
      border: none;
      border-radius: 20px;
      width: 100%;

      .el-form-item {
        // border: 1px solid rgba(247, 235, 235, 0.2);
        margin: 0 13px;

        .el-form-item__label {
          padding: 0 10px 0 0;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #d1d4dc;
          margin: 0 !important;
        }
        .el-form-item__content {
          .el-select {
            //margin-left: 20px;
            .el-input {
              .el-input__inner {
                height: 36px !important;
                background: rgba(159, 159, 159, 0.1) !important;
                border: none;
                border-radius: 4px !important;
                color: #d1d4dc;
              }
              .el-input__inner.is-focus {
                color: #d1d4dc;
              }
              .el-select__caret {
                line-height: 36px;
              }
            }
          }
          .exercise-day {
            margin: 0 2px;
            .el-form-item__content {
              .el-date-editor {
                .el-input__prefix {
                  .el-input__icon {
                    color: #ffff;
                  }
                }
                .el-input__inner {
                  background: rgba(216, 216, 216, 0.1);
                  width: 120px;
                  height: 36px;
                  border-radius: 4px;
                  border: none;
                  margin-top: 2px;
                  line-height: 36px;
                  font-size: 12px;
                  padding-right: 20px !important;
                }
              }
            }
          }
          .el-button {
            margin: 0 !important;
            padding: 0 !important;
            border: none;
            width: 80px;
            height: 36px;
            border-radius: 4px;
            background-color: #efc394 !important;

            span {
              font-size: 12px;
              font-family: MicrosoftYaHei;
              color: #000000;
              font-weight: bold;
            }
          }
        }
      }
    }

    .el-select {
      color: #ffff !important;
      .el-input {
        .el-input__inner {
          border-radius: 5px;
          color: #ffff;
          border: none;
          margin-top: 2px;
        }
      }
      .el-input__inner {
        margin: 0 5px;
        width: 100px !important;
        // color: #ffff !important;
        border-bottom: none;
      }
      .el-input__inner:focus {
        border-color: #efc394 !important;
      }
      .el-select__caret {
        color: #ffff !important;
      }
    }
  }
  .deribit-table {
    .el-table__header-wrapper {
      .has-gutter {
        tr {
          border-bottom: none !important;
          .el-table__cell {
            height: 60px !important;
            text-align: center;
            color: rgba(239, 195, 148, 1) !important;
            background-color: rgba(45, 47, 58, 1) !important;
            font-size: 14px;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
            .cell {
              font-size: 14px;
              font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
              font-weight: bold;
            }
          }
        }
      }
    }
    th {
      // background: rgb(40, 48, 70) !important;
      color: #efc394 !important;
      text-align: center;
    }
    td {
      //background: rgb(40, 48, 70) !important;
      text-align: center;
    }
    tr {
      height: 60px;
      cursor: auto;
      border-bottom: none !important;
    }
    tr:hover {
      .cell a {
        color: #ffff !important;
      }
    }
    // .is-scrolling-none .el-table__body .el-table__row :hover {
    //   background: #EFC394 !important;
    // }
    td.el-table__cell,
    th.el-table__cell.is-leaf {
      border-bottom: none !important;
    }
    .cell a {
      color: #efc394 !important;
      font-weight: 600;
      transition: all 0.5s;
    }
    .cell a:hover {
      font-size: 15px;
      color: #ffff !important;
      font-weight: 600;
      transform: scale(1.5);
    }
  }

  .block {
    .el-pagination {
      text-align: center;
      padding: 30px 20px 0 !important;
      .el-pagination__sizes {
        .el-input {
          .el-input__inner {
            color: #ffff !important;
            border: none;
          }
          .el-input__inner:focus {
            border-color: #efc394 !important;
          }
          .el-input__inner:hover {
            border-color: #efc394 !important;
          }
        }
        .el-select__caret {
          color: #ffff !important;
        }
      }
      .btn-prev {
        color: #ffff !important;
        background-color: transparent !important;
      }
      .el-pager {
        li.active {
          color: #efc394 !important;
        }
        .number {
          background-color: transparent !important;
          color: #ffff !important;
        }
        .el-icon {
          background-color: transparent !important;
          color: #ffff !important;
        }
      }
      .btn-next {
        background-color: transparent !important;
        .el-icon {
          color: #ffff !important;
        }
      }
    }
  }

  .dark-layout .el-table {
    border-bottom: 1px solid rgba(247, 235, 235, 0.2) !important;
  }
}
</style>
<style lang="scss">
.oddRow {
  //斑马纹样式

  color: white;

  background-color: rgba(102, 102, 102, 0.1) !important;
  .el-table__cell {
    padding: 0 !important;
    margin: 0 !important;
    .cell {
      font-size: 14px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #d1d4dc;
    }
  }
}

.evenRow {
  background-color: rgba(255, 255, 255, 0.1) !important;

  color: white;
  .el-table__cell {
    padding: 0 !important;
    margin: 0 !important;
    .cell {
      font-size: 14px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #d1d4dc;
    }
  }
}
</style>